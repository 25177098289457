import { Breadcrumb } from "antd";
import styled from "styled-components";

export const BreadCrumbStyled = styled(Breadcrumb)`
    background: rgba(240,240,240,0.8);
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: start;
    padding-left: 25px;
    padding-top: 40px;
    /* padding-bottom: 20px; */
    overflow: hidden;
    white-space: nowrap;
`;