import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { OutletContainer } from './styles';
import Header from './Header';
import Footer from './Footer';
import BreadcrumbsComponent from 'components/breadcrumbs';
import { useAuth } from 'hooks/auth';
import LoginWrapper from 'pages/login';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();
  const { token } = useAuth();

  const haveToken = !!token;

  const cpf =
    location.pathname.includes('/page-code') ||
    location.pathname.includes('/registrar-usuario')
      ? location.pathname.split('/')[2]
      : '';

  // paths que não precisam renderizar o header e breadcrumb
  const pathsToDontRender = ['/login'];

  // paths para renderizar o header sem tá logado
  const pathsToRenderHeaderWithoutLogged = [
    '/registrar-usuario',
    `/registrar-usuario/${cpf}`,
    '/recuperar-senha',
    `/page-code/${cpf}`,
    '/duvidas-frequentes',
    '/sobre',
    '/termo-adequacao-lgpd',
  ];

  // verificação se é para renderizar o header e breadcrumb ou não
  const isNotToRender = Array(location.pathname).some(path =>
    pathsToDontRender.includes(path)
  );

  const isRenderHeaderWithoutLogged = Array(location.pathname).some(path =>
    pathsToRenderHeaderWithoutLogged.includes(path)
  );

  const isFaqPage = Array(location.pathname).some(
    path => path === '/duvidas-frequentes' || path === '/sobre'
  );

  const isEditUserPage = Array(location.pathname).some(
    path => path === `/registrar-usuario/${cpf}`
  );

  return (
    <>
      {haveToken ? (
        <>
          {!isNotToRender && <Header isLogged={true} />}
          <div>
            {!isNotToRender && !isEditUserPage && <BreadcrumbsComponent />}
            <OutletContainer
              className={isNotToRender || isEditUserPage ? 'loginScreen' : ''}
            >
              {children}
            </OutletContainer>
          </div>
          <Footer />
        </>
      ) : (
        <>
          {isRenderHeaderWithoutLogged && <Header isLogged={false} />}
          <div>
            <OutletContainer className={isFaqPage ? '' : 'loginScreen'}>
              {isNotToRender || isRenderHeaderWithoutLogged ? (
                children
              ) : (
                <LoginWrapper />
              )}
            </OutletContainer>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Layout;
