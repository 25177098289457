/* eslint-disable react/jsx-filename-extension */
import React, { ReactNode } from 'react';
import { AuthProvider } from './auth';
import { GlobalDataProvider } from './globalData';

// eslint-disable-next-line react/prop-types
function AppProvider({ children }: { children: ReactNode }) {
  return (
    <AuthProvider>
      <GlobalDataProvider>{children}</GlobalDataProvider>
    </AuthProvider>
  );
}

export default AppProvider;
