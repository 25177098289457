/* eslint-disable react/jsx-filename-extension */
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

if(process?.env.REACT_APP_AMB !== "dev") {
  console.log = function() {};
  console.debug =function() {};
  console.info = function() {};
  console.warn = function() {};
  console.error = function() {};
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();