import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from 'react';
import { useAuth } from './auth';
import { GlobalProps, ModeloProps } from 'interfaces/globalData';

// @ts-ignore
const GlobalDataContext = createContext<GlobalProps>();

function GlobalDataProvider({ children }: { children: ReactNode }) {
  const { token } = useAuth();

  const [aparelhos, setAparelhos] = useState([]);
  const [isModalVisible, setModalVisivel] = useState(false);
  const [isModalDeleteVisible, setModalDeleteVisivel] = useState(false);
  const [id, setIdCelular] = useState('');
  const [possuiBO, setPossuiBO] = useState(true);
  const [possuiChave, setPossuiChave] = useState(true);
  const [ocorrencias, setOcorrencias] = useState([]);
  const [procedimentos, setProcedimentos] = useState([]);

  const [restituicao, setRestituicao] = useState(false);
  const [isPreAlerta, setIsPreAlerta] = useState(false);
  const [isRoubado, setIsRoubado] = useState(false);
  const [isPerdido, setIsPerdido] = useState(false);
  const [isFurtado, setIsFurtado] = useState(false);
  const [preencheJustificativa, setPreJustificativa] = useState(false);

  const [fabricantes, setFabricantes] = useState([]);
  const [modelos, setModelos] = useState<ModeloProps[]>([]);

  const deleteAll = useCallback(() => {
    setAparelhos([]);
    setOcorrencias([]);
    setProcedimentos([]);
    setModalVisivel(false);
    setModalDeleteVisivel(false);
    setIdCelular('');
    setPossuiBO(true);
    setRestituicao(false);
    setIsPreAlerta(false);
    setIsRoubado(false);
    setPreJustificativa(false);
  }, []);

  useEffect(() => {
    if (!token) {
      deleteAll();
    }
  }, [token, deleteAll]);

  return (
    <GlobalDataContext.Provider
      value={{
        aparelhos,
        setAparelhos,
        isModalVisible,
        setModalVisivel,
        isModalDeleteVisible,
        setModalDeleteVisivel,
        id,
        setIdCelular,
        possuiBO,
        setPossuiBO,
        ocorrencias,
        setOcorrencias,
        procedimentos,
        setProcedimentos,
        restituicao,
        setRestituicao,
        isPreAlerta,
        setIsPreAlerta,
        isRoubado,
        setIsRoubado,
        isPerdido,
        setIsPerdido,
        isFurtado,
        setIsFurtado,
        preencheJustificativa,
        setPreJustificativa,
        deleteAll,
        fabricantes,
        setFabricantes,
        modelos,
        setModelos,
        possuiChave,
        setPossuiChave,
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
}

function useGlobalData() {
  const context = useContext(GlobalDataContext);
  return context;
}

export { GlobalDataProvider, useGlobalData };
