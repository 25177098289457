import { apiAuth } from './auth';

const CodeVerification = {
  saveCodeVerification: ({ cpf, code, password }) =>
    apiAuth.post('createPassword', {
      cpf,
      code,
      password,
    }),

  sendCodeVerification: cpf =>
    apiAuth.post(`sendcode?cpf=${cpf}&application_id=4`),

  resendEmail: (email, cpf) =>
    apiAuth.post(`resendcode?cpf=${cpf}&email=${email}&application_id=4`),

  createPassword: values => apiAuth.post('createPassword', values),
};
// export const SendCode = {

// };
export default CodeVerification;
