import axios from 'axios';
import ENV from '../url_config';
const { accessCitizenServer, appKey } = ENV;

const api = axios.create({
  baseURL: accessCitizenServer,
  timeout: 1000 * 5,
});

type TAuthMe = {
  client: {
    userId: string;
    name: string;
    email: string;
    organization: string;
    userAccountInfo: {
      deprecatedDetails: boolean;
      deprecatedPassword: boolean;
      lastPasswordUpdate: string;
      passwordExpirationDate: string;
      has2FA: boolean;
      twoFactorRequired: boolean;
    };
  };
  tokenType: string;
  roles: string[];
  loginType: string;
};

const redirectUri = 'https://spca-dev.sspds.ce.gov.br/web/meucelular/login';
export const AccessCitizenService = {
  validate: async (token: string) =>
    await api.get<TAuthMe>('/api/auth/me', {
      headers: {
        Authorization: token,
        'Accept-Client': appKey,
      },
    }),
  loggout: async (grantToken: string) =>
    await api.delete('/api/auth', {
      headers: {
        Authorization: grantToken,
        'Accept-Client': appKey,
      },
    }),
  recoveryPassword: async (cpf: string) =>
    await api.get(`users/recovery/${cpf}?redirectAppId=${redirectUri}`),
};
