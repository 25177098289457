import styled from 'styled-components';
import { shade } from 'polished';

const Container = styled.button`
  background: #008241;
  height: 40px;
  border-radius: 50px;
  border: 0;
  padding: 0 16px;
  color: #ffffff;
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;
  font-weight: bold;
  &:hover {
    background: ${shade(0.2, '#008241')};
  }
`;

export default Container;
