import { Card } from 'antd';
import styled from 'styled-components';

const CardStyled = styled(Card)`
  height: 350px;
  width: 250px;
  padding: 5px;
  border-radius: 10px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 5px rgb(200, 200, 200);

  @media screen and (max-width: 767px) {
    box-shadow: 1px 1px 5px grey;
    margin-right: 0px;
    padding-top: 20px;
    height: 100%;
    width: 215px;
    /* p {
      width: 215px;
    }
    .separator {
      width: 195px !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
    } */
    .separator {
      width: 100% !important;
    }
  }

  /* @media screen and (max-width: 360px) {
    

    .separator {
      width: 100% !important;
    }
  } */

  p {
    text-align: center;
    text-wrap: wrap;
    padding-right: 20px;
    padding-left: 20px;
    @media screen and (min-width: 767px) {
      width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
    }
  }

  .ant-card-body::after {
    display: none !important;
  }

  .ant-card-cover {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6cd2d9;
    height: 130px;
    width: 130px;
    border-radius: 100%;
    color: white;
    font-size: 70px;
    background: ${(props: any) =>
      props.color === '#fff' ? '#1DA57A' : props.color};

    /* @media screen and (max-width: 767px) {
            height: 500px;
            width: 115px;
            margin-top: 5px;            
        } */
  }

  .separator {
    background: #b9b9b9;
    height: 1px;
    width: 220px;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .ant-card-meta-description {
    text-align: center;
  }

  .contentActionsButtons {
    display: flex;
    justify-content: center;
  }

  .contentActionsButtons button {
    font-size: 27px !important;
    border: none;
    background: transparent;
    transition: all 0.3s ease;
    &:hover {
      scale: 1.2;
    }
  }

  #infoIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 17px;
    color: ${props => props.color};
  }

  #editButton {
    color: #54657a;
    &:hover {
      color: #efcc00;
    }
  }

  #alertButton {
    color: #54657a;
    &:hover {
      color: #d64343;
    }
  }

  #deleteButton {
    color: #54657a;
    &:hover {
      color: #555555;
    }
  }

  .addBoButton span {
    font-size: 27px;
    color: #54657a;
    transition: all 0.3s ease;
    &:hover {
      color: #a52a2a;
      scale: 1.2;
    }
  }

  .restitutionButton span {
    font-size: 27px;
    color: #54657a;
    transition: all 0.3s ease;
    &:hover {
      color: green;
      scale: 1.2;
    }
  }
`;

export const AlertButton = styled.p`
  /* margin-left: 50px; */
  color: ${props => props.color};
`;

export default CardStyled;
