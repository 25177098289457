import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalContainer = styled(Modal)`
  min-width: 650px;

  .ant-modal-body {
    padding: 0px !important;

    border: 0px white !important;
  }

  @media only screen and (max-width: 801px) {
    min-width: 400px !important;
  }
`;
