import React, { useCallback } from 'react';
import { Input } from 'antd';

interface InputImeiProps {
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
}

export default function InputImei({
  className,
  value,
  onChange,
  disabled,
  placeholder,
  type,
}: InputImeiProps) {
  const handleChange = useCallback((e: any) => {
    if ((e.target && e.target.value.length > 16) || !onChange) {
      // devPrint(e.target.value)
      return;
    }
    onChange(e.target.value);
  }, []);

  return (
    <Input
      value={value}
      onChange={handleChange}
      disabled={disabled}
      placeholder={placeholder}
      className={className}
      type={type}
      suffix={null}
    />
  );
}
