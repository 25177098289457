import { Route, Routes } from 'react-router-dom';

import LoginWrapper from '../login';

import RegisterPhones from '../registerPhone';
import RegisterUser from '../registerUser';
import PageValidation from '../PageValidation';

import PerguntasFrequentes from '../PerguntasFrequentes';

import Home from '../home';
import RecuperaSenha from '../recuperaSenha';
import AboutPage from 'pages/about';
import DeviceDetails from 'pages/deviceDetails';
import AdequacyTerm from 'pages/adequacyTerm';

export default function ProtectRoutes() {
  return (
    <Routes>
      <Route path="/registrar-usuario" element={<RegisterUser />} />
      <Route path="/registrar-usuario/:id" element={<RegisterUser />} />
      <Route path="/recuperar-senha" element={<RecuperaSenha />} />
      <Route path="/page-code/:id" element={<PageValidation />} />
      <Route path="/login" element={<LoginWrapper />} />

      <Route path="/registrar-dispositivo" element={<RegisterPhones />} />
      <Route path="/registrar-dispositivo/:id" element={<RegisterPhones />} />
      <Route path="/detalhes/:id" element={<DeviceDetails />} />
      <Route path="/duvidas-frequentes" element={<PerguntasFrequentes />} />
      <Route path="/termo-adequacao-lgpd" element={<AdequacyTerm />} />
      <Route path="/sobre" element={<AboutPage />} />
      <Route path="/home" element={<Home />} />
      <Route path="*" element={<h1>PAGINA NÃO ENCONTRADA</h1>} />
    </Routes>
  );
}
