import {
  AlertOutlined,
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  InfoCircleOutlined,
  MobileOutlined,
  PlusOutlined,
  RotateLeftOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Card, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEncription } from 'utils/encription';
import CardStyled, { AlertButton } from './style';
const { Meta } = Card;

interface CardProps {
  id: string;
  alert: string;
  producer: string;
  model: string;
  imeis: string;
  setShowAlertModal: any;
  setShowBOModal: any;
  setShowRestitutionModal: any;
  setShowDeleteModal: any;
  setCardId: any;
  // setModalVisibility: any
  // onClickAlert: () => void,
  // onClickDelete: () => void,
  // onClickRestituicao: () => void,
}

export default function CardAntd({
  id,
  alert,
  producer,
  model,
  imeis,
  setShowAlertModal,
  setShowBOModal,
  setShowRestitutionModal,
  setShowDeleteModal,
  setCardId,
}: CardProps) {
  const navigate = useNavigate();
  const statusAlert = {
    text:
      alert === 'Alerta'
        ? 'Em Alerta'
        : alert === 'Pre Alerta'
        ? 'Em Pré Alerta'
        : 'Sem alerta',
    color:
      alert === 'Alerta'
        ? '#db5d5d'
        : alert === 'Pre Alerta'
        ? '#ff9b05'
        : '#228b22 ',
  };

  const handleNavigate = () => {
    const idEncrypt = useEncription(id.toString(), 'encrypt');
    navigate(`/registrar-dispositivo/${model}`, {
      state: {
        idEncrypted: idEncrypt,
      },
    });
  };

  return (
    <CardStyled
      key={id}
      color={statusAlert.color}
      cover={<MobileOutlined onClick={() => navigate(`/detalhes/${id}`)} />}
      actions={[
        <AlertButton color={statusAlert.color}>{statusAlert.text}</AlertButton>,
      ]}
      hoverable
    >
      <Meta
        title={<p key={id}> {model.toUpperCase()} </p>}
        description={
          <>
            <div className="separator"></div>
            {statusAlert.text === 'Em Alerta' ? (
              <div style={{ padding: '15px' }} />
            ) : // <Tooltip title='Restituir' color='green'>
            //     <Button
            //         onClick={() => {
            //             setShowRestitutionModal(true);
            //             setCardId(id);
            //         }}
            //         // onClick={() => setModalVisibility({restitutionModal: { value:true, id: id, loading: false }})}
            //         // children="Restituição"
            //         className="restitutionButton"
            //         icon={<SyncOutlined />}
            //     />
            // </Tooltip>
            statusAlert.text === 'Em Pré Alerta' ? (
              <Tooltip title="Anexar um procedimento policial" color="#a52a2a">
                <Button
                  onClick={() => {
                    setShowBOModal(true);
                    setCardId(id);
                  }}
                  // onClick={() => setModalVisibility({preAlertModal: { value:true, id: id, loading: false }})}
                  // children="Complemento"
                  className="addBoButton"
                  icon={<FileAddOutlined />}
                />
              </Tooltip>
            ) : (
              <div className="contentActionsButtons">
                <Tooltip title="Editar" color="#efcc00">
                  <Button onClick={handleNavigate} id="editButton">
                    <EditOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Adicionar um alerta" color="#D64343">
                  <Button
                    onClick={() => {
                      setShowAlertModal(true);
                      setCardId(id);
                    }}
                    id="alertButton"
                  >
                    <AlertOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Deletar" color="#555555">
                  <Button
                    onClick={() => {
                      setShowDeleteModal(true);
                      setCardId(id);
                    }}
                    id="deleteButton"
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </div>
            )}
          </>
        }
      />
      <Tooltip title="Detalhes" color={statusAlert.color} placement="right">
        <InfoCircleOutlined
          id="infoIcon"
          onClick={() => navigate(`/detalhes/${id}`)}
        />
      </Tooltip>
    </CardStyled>
  );
}
