import React, { SetStateAction } from 'react';
import { Button } from 'antd';
import { TPendingsuser } from 'pages/home';
import video from '../../assets/tutorial-complemento-info.mp4';
import ENV from '../../url_config';
import { AddUserInfoModalContainer } from './addUserInfoStyle';
const { accessCitizenServer } = ENV;

type TAddUserInfoModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<SetStateAction<boolean>>;
  pendings: TPendingsuser[];
};

function AddUserInfoModal({
  isModalOpen,
  setIsModalOpen,
  pendings,
}: TAddUserInfoModalProps) {
  const disableItemStyle = {
    color: 'grey',
    margin: 0,
    textDecorationLine: 'line-through',
  };
  return (
    <AddUserInfoModalContainer
      title={<h3 className="content-title">Informações pendentes</h3>}
      footer={
        <div className="content-footer">
          <Button
            style={{ background: '#56bfc9', color: '#fff' }}
            onClick={() => window.location.reload()}
          >
            Recarregar página
          </Button>
          <Button
            type="primary"
            onClick={() =>
              window.open(`${accessCitizenServer}/login`, '_blanck')
            }
          >
            Adicionar informações
          </Button>
        </div>
      }
      visible={isModalOpen}
      closable={false}
      destroyOnClose
    >
      <div style={{ fontSize: '1rem' }}>
        <span>
          Para acessar o sistema <b>Meu Celular</b>, complete seu cadastro na
          plataforma <b>Acesso Cidadão</b>. Informações pendentes:
        </span>
        <br />
        <ul className="content-requirements">
          {pendings.map(pending => (
            <li
              defaultChecked={pending.value}
              key={pending.id}
              style={pending.value ? disableItemStyle : { margin: 0 }}
            >
              {pending.label}
            </li>
          ))}
        </ul>
        <div>
          <p>Vídeo tutorial de como complementar esses dados:</p>
          <video controls style={{ width: '100%' }}>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </div>
    </AddUserInfoModalContainer>
  );
}

export default AddUserInfoModal;
