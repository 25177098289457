import { Tooltip, Typography } from "antd";
import type { ColumnsType } from 'antd/es/table';
import { Occurrences } from "interfaces/history";
import moment from "moment";

const { Text } = Typography;
export const dataHistoryColumns:ColumnsType<Occurrences> = [
    {
      key: '1',
      title: <b>Status</b>,
      dataIndex: 'statusAlerta',
      render: (text: any, record: any, index: number) => {
        const dateFormated = moment(record?.dataAtualizacao, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")
        const justifyText = record?.justificativaRemoveAlerta && <> {dateFormated} <br/> {record?.justificativaRemoveAlerta} </>
        switch (text) {
          case 'ALERTA':
            return (
              <Tooltip title={justifyText}>
                <Text
                  key={index}
                  disabled={record.flagCancelado}
                  delete={record.flagCancelado}
                  type="danger"
                  strong
                >
                  ALERTA
                </Text>
              </Tooltip>
            );
          case 'SEM_ALERTA':
            return (
              <Tooltip title={justifyText}>
                <Text
                  key={index}
                  disabled={record.flagCancelado}
                  delete={record.flagCancelado}
                  type="success"
                  strong
                >
                  SEM ALERTA
                </Text>
              </Tooltip>
            );
          case 'PRE_ALERTA':
            return (
              <Tooltip title={justifyText}>
                <Text
                  key={index}
                  disabled={record.flagCancelado}
                  delete={record.flagCancelado}
                  type="warning"
                  strong
                >
                  PRE ALERTA
                </Text>
              </Tooltip>
            );
          case 'RESTITUIDO':
            return (
              <Tooltip title={justifyText}>
                <Text
                  key={index}
                  disabled={record.flagCancelado}
                  delete={record.flagCancelado}
                  type="success"
                  style={
                    record.flagCancelado
                      ? { color: '#BBBBBB' }
                      : { color: '#1E90FF' }
                  }
                  strong
                >
                  RESTITUIDO
                </Text>
              </Tooltip>
            );
          case 'CANCELADO':
            return (
              <Tooltip title={justifyText}>
                <Text
                  key={index}
                  disabled={record.flagCancelado}
                  delete={record.flagCancelado}
                  type="secondary"
                  strong
                >
                  CANCELADO
                </Text>
              </Tooltip>
            );
          default:
            return `${' '}`;
        }
      },
    },
    {
      key: '4',
      title: <b>Situacão</b>,
      dataIndex: 'situacaoCelular',
      render: (text: any, record: any) => {
        const dateFormated = moment(record?.dataAtualizacao, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")
        const justifyText = record?.justificativaRemoveAlerta && <> {dateFormated} <br/> {record?.justificativaRemoveAlerta} </>
        return (
          <Tooltip title={justifyText}>
            <Text disabled={record.flagCancelado} delete={record.flagCancelado}>
              {text}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      key: '2',
      title: <b>Inserido em</b>,
      dataIndex: 'dataCriacao',
      responsive: ['sm'],
      render: (text: any, record: any, index: number) => {
        const data = new Date(parseInt(text) * 1000).toLocaleDateString();
        const dateFormated = moment(record?.dataAtualizacao, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")
        const justifyText = record?.justificativaRemoveAlerta && <> {dateFormated} <br/> {record?.justificativaRemoveAlerta} </>

        if (data === 'Invalid Date') {
          return ' ';
        } else {
          return (
            <Tooltip title={justifyText}>
              <Text disabled={record.flagCancelado} delete={record.flagCancelado}>
                {moment(text, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}
              </Text>
            </Tooltip>
          );
        }
      },
    },
    {
      key: '3',
      title: <b>Procedimento</b>,
      responsive: ['md'],
      render: (text: any, record: any, index: number) => {
        const dateFormated = moment(record?.dataAtualizacao, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")
        const justifyText = record?.justificativaRemoveAlerta && <> {dateFormated} <br/> {record?.justificativaRemoveAlerta} </>
        switch (record.tipoProcedimento) {
          case 'BOLETIM_DE_OCORRENCIA':
            // eslint-disable-next-line no-case-declarations
            const procedureTextBo = `BO ${record.numeroProcedimento}`;
            return (
              <Tooltip title={justifyText}>
                <Text
                  disabled={record.flagCancelado}
                  delete={record.flagCancelado}
                  strong
                >
                  {procedureTextBo}
                </Text>
              </Tooltip>
            );
          case 'INQUERITO':
            // eslint-disable-next-line no-case-declarations
            const procedureTextIn = `IP ${record.numeroProcedimento}`;
            return (
              <Tooltip title={justifyText}>
                <Text
                  disabled={record.flagCancelado}
                  delete={record.flagCancelado}
                  strong
                >
                  {procedureTextIn}
                </Text>
              </Tooltip>
            );
          case 'ATO_INFRACIONAL':
            // eslint-disable-next-line no-case-declarations
            const procedureTextAi = `TCO ${record.numeroProcedimento}`;
            return (
              <Tooltip title={justifyText}>
                <Text
                  disabled={record.flagCancelado}
                  delete={record.flagCancelado}
                  strong
                >
                  {procedureTextAi}
                </Text>
              </Tooltip>
            );
          default:
            return <Text />;
        }
      },
    },
    
  ];