import { useAuth } from "hooks/auth";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "./style";


export default function BackButton({page, styleProps} : {page?: string, styleProps?: any}) {
    const navigate = useNavigate();
    const { token } = useAuth();
    const defaultPath = token ? '/home' : '/login';

    return(
        <Container 
            onClick={() => (page ? navigate(page) : navigate(defaultPath))}
            style={styleProps}
            color='#00853b'
        >
            <Button />
            <div 
                className="content-text"
            >
                Voltar
            </div>
        </Container>
    )
}