/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import Container from './styles';

function Button({ children, loading, ...rest }) {
  return (
    <Container type="button" {...rest}>
      {loading ? 'Carregando ...' : children}
    </Container>
  );
}

export default Button;
