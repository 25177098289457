import Layout from '../layout';
import ProtectRoutes from './routes';

export default function Root() {
  return (
    <Layout>
      <ProtectRoutes />
    </Layout>
  );
}
