import { Modal } from 'antd';
import { SaveButton } from 'pages/home/modals/style';

interface ModalProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  handleLawModalSubmit?: any;
}

export const Art340Modal = ({
  isVisible,
  setIsVisible,
  handleLawModalSubmit,
}: ModalProps) => {
  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => setIsVisible(false)}
      zIndex={9999}
      title="Código Penal - Decreto -Lei nº 2.848"
    >
      <div>
        <p
          style={{
            padding: '10px',
          }}
        >
          Art. 340 - Provocar a ação de autoridade, comunicando - lhe a
          ocorrência de crime ou de contravenção que sabe não se ter verificado:
          Pena - detenção, de um a seis meses, ou multa.
        </p>
      </div>

      {handleLawModalSubmit ? (
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
          <SaveButton color="green" onClick={() => handleLawModalSubmit()}>
            Ok
          </SaveButton>
          <SaveButton
            color="rgb(255, 69, 0)"
            onClick={() => setIsVisible(false)}
          >
            Cancelar
          </SaveButton>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <SaveButton color="green" onClick={() => setIsVisible(false)}>
            Ok
          </SaveButton>
        </div>
      )}
    </Modal>
  );
};
