/* eslint-disable no-unused-vars */
import axios, { AxiosError } from 'axios';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';
import devPrint from '../utils/devPrint';
import { SiaaData, ITalkToUsBody } from 'interfaces/auth';
import { UserProps } from 'interfaces/user';
import ENV from '../url_config';
import { DEFAULT_ERROR_MESSAGE } from 'utils/defaultMessages';

const { authServer } = ENV;

const apiAuth = axios.create({
  baseURL: authServer,
});

apiAuth.interceptors.response.use(
  response => response,
  error => {
    let errorMessage;
    if (
      error?.response?.data?.messageForUser &&
      error?.response?.data?.messageForUser !==
        'This connection has been closed'
    ) {
      return Promise.reject(error?.response?.data?.messageForUser);
    } else {
      if (error.message == 'Network Error') {
        sessionStorage.removeItem('@SSPDS_ALERTA:token');
        sessionStorage.removeItem('@SSPDS_ALERTA:client');
        errorMessage = 'Serviço temporariamente indisponivel.';
      } else {
        errorMessage = DEFAULT_ERROR_MESSAGE;
      }
    }
    return Promise.reject(errorMessage);
  }
);

function AuthService() {
  const errorHandler = (err: any) => {
    toast.error(err || 'Ocorreu um erro interno no sistema, tente novamente');
  };

  const login = async (
    cpf: string,
    password: string
  ): Promise<SiaaData | any> => {
    try {
      const res = await apiAuth.post('login', {
        cpf,
        password,
      });

      const { token, name, cpf: cpfUSer, cellphone, firstAccess } = res.data;
      const client = {
        name,
        cpf: cpfUSer,
        cellphone,
      };
      return { token, client, firstAccess };
    } catch (err: any) {
      errorHandler(err);
    }
  };

  const logout = () => {
    sessionStorage.removeItem('@SSPDS_ALERTA:token');
    sessionStorage.removeItem('@SSPDS_ALERTA:client');
  };

  const register = async (userBody: UserProps) =>
    await apiAuth.post('/user', userBody);

  const update = async (cpf: string, userBody: UserProps) =>
    await apiAuth.put(`/user/update?cpf=${cpf}`, userBody);
  const validateCpf = async (cpf: string) => {
    // try {
    return await apiAuth.post(`oauth/validateCPF?cpf=${cpf}&application_id=4`);
    // } catch (err) {
    //   errorHandler(err);
    // }
  };

  const validateEmail = async (cpf: string, email: string) => {
    try {
      return await apiAuth.post(
        `/oauth/validateEmail?cpf=${cpf}&email=${email}&application_id=4`
      );
    } catch (err) {
      errorHandler(err);
    }
  };

  const checkCode = async (params: any) => {
    try {
      return await apiAuth.post('/checkcoderole?application_id=4', params);
    } catch (err) {
      errorHandler(err);
    }
  };

  const talkToUs = async (body: ITalkToUsBody) => {
    return await apiAuth.post('/talk-to-us', body);
  };

  const hasAgreedWithLgpd = async (cpf: string) =>
    await apiAuth.get(`/user/tos?cpf=${cpf}`);

  const setAgreeWithLgpd = async (cpf: string) =>
    await apiAuth.post(`/user/tos?cpf=${cpf}`);

  return {
    login,
    logout,
    register,
    update,
    validateCpf,
    validateEmail,
    checkCode,
    talkToUs,
    hasAgreedWithLgpd,
    setAgreeWithLgpd,
  };
}

export { apiAuth, AuthService };
