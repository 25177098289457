export const articles = [
  {
    key: 1,
    title: 'Art. 1º',
    content:
      'A    Secretaria de Segurança Pública e Defesa Social do Estado do Ceará,    atua com a missão de zelar pela ordem pública e pela incolumidade das    pessoas e do patrimônio, coordenando, controlando e integrando as    ações da Polícia Civil do Estado do Ceará (PCCE), da Polícia Militar    do Ceará (PMCE), do Corpo de Bombeiros Militar do Estado do Ceará    (CBMCE), da Perícia Forense do Estado do Ceará (Pefoce), da Academia    Estadual de Segurança Pública (Aesp) e da Superintendência de Pesquisa    e Estratégia de Segurança Pública (Supesp), ocasião em que dados    sensíveis/pessoais são coletados e recepcionados com extremo respeito    à privacidade',
  },
  {
    key: 2,
    title: 'Art. 2º',
    content:
      ' A Secretaria da Segurança Pública    compromete-se a garantir a guarda e proteção dos dados pessoais    tratados em suas atividades, observando as disposições da Lei Geral de    Proteção de Dados (LGPD), adotando medidas técnicas e administrativas    adequadas para assegurar a integridade, confidencialidade e    disponibilidade das informações. §1º- A guarda dos dados pessoais será    realizada de forma a permitir o acesso apenas a pessoas autorizadas,    que necessitem dessa informação para o desempenho de suas funções, e    em conformidade com as finalidades para as quais os dados foram    coletados. §2º- Os dados pessoais sob guarda desta Secretaria não    poderão ser revelados/ fornecidos a terceiros, com exceção da prévia    autorização por escrito do titular dos dados pessoais, ou ainda na    hipótese de prestação de serviços por contratação de Empresa mediante    celebração de Termo de Confidencialidade e Sigilo. §3º- A contratação    de serviços de empresas pela administração pública, quando envolver o    tratamento de dados pessoais, não será obstaculizada desde que esteja    demonstrada a conformidade com a LGPD, com adoção de medidas de    proteção de dados compatíveis com as exigências legais; I- Será    formalizado no contrato cláusulas específicas sobre a proteção de    dados, estabelecendo responsabilidades e penalidades em caso de    descumprimento; II- Será feita uma análise prévia das atividades de    tratamento de dados propostas pelas empresas, assegurando que não haja    risco à privacidade dos titulares; III- A Secretaria mantenha    supervisão e monitoramento contínuo das atividades das empresas    contratadas em relação ao tratamento de dados pessoais.',
  },
  {
    key: 3,
    title: 'Art. 3º',
    content:
      'A    Secretaria de Segurança Pública e Defesa Social do Estado do Ceará, se    compromete em: §1º- Realizar mapeamento de todos os dados pessoais que    são coletados, armazenados e tratados durante suas atividades, bem    como: I- Realizar avaliações periódicas de impactos à proteção dos    dados; II- Adotar medidas técnicas e administrativas para proteger os    dados pessoais contra acessos não autorizados, perdas ou vazamentos.    §2º- Estabelecer procedimentos viabilizando o exercício dos direitos    dos titulares de dados, incluindo acesso, correção, exclusão e    revogação de consentimento;',
  },
  {
    key: 4,
    title: 'Art. 4º',
    content:
      'A Secretaria de Segurança Pública e Defesa Social do Estado do Ceará assegura a confidencialidade e a integridade de todos os dados pessoais mantidos ou consultados/transmitidos eletronicamente, garantindo a proteção desses dados contra acesso não autorizado, destruição, uso, modificação, divulgação ou perda acidental e/ou indevida. §1º: Para fins de clareza, os dados pessoais correspondem as informações relacionadas a pessoas naturais identificadas ou identificáveis.',
  },
  {
    key: 5,
    title: 'Art. 5º',
    content:
      'A Secretaria de Segurança Pública e Defesa Social do Estado do Ceará manterá atualizados os registros das atividades de tratamento de dados, conforme exigido pela LGPD. §1º Assegura o comprometimento a tratar os dados pessoais aos quais tiver acesso somente mediante as devidas permissões dos titulares. §2º Caso o tratamento dos dados pessoais se enquadre nas hipóteses excepcionais previstas no inciso III do artigo 4º da Lei nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados), não será necessária a permissão ou o consentimento do titular dos dados, devendo ser observado o devido processo legal, os princípios gerais de proteção e os direitos do titular de dados previstos na LGPD. §3º Da mesma forma, quando o tratamento de dados pessoais se configurar nas exceções previstas nos artigos 7º e 11 da Lei Geral de Proteção de Dados, a necessidade de consentimento do titular poderá ser dispensada. §4º Em tais casos excepcionais, o tratamento de dados pessoais será realizado em conformidade com o capítulo IV, seção I, da Lei Geral de Proteção de Dados, assegurando a devida segurança e proteção dos direitos dos titulares, em estrita observância aos princípios gerais estabelecidos na referida legislação.',
  },
  {
    key: 6,
    title: 'Art. 6º',
    content:
      'Ao cadastro voluntário nos sistemas/plataformas gerenciados pela Secretaria de Segurança Pública e Defesa Social do Estado do Ceará, a autorização do titular, embora intencional e explícita, será destinada consulta de consentimento, o informando as finalidades específicas para as quais foram coletados.',
  },
  {
    key: 7,
    title: 'Art. 7º',
    content:
      'A Secretaria de Segurança Pública e Defesa Social do Estado do Ceará assegura que todos os seus servidores e colaboradores contratados estejam cientes e treinados sobre a importância da proteção de dados pessoais que estão sob sua responsabilidade, assinaram o Termo de Confidencialidade, Compromisso e Sigilo, bem como comprometem-se a manter quaisquer dados pessoais em sigilo e não os utilizar para outros fins.',
  },
];
