import { Card } from "antd";
import styled from "styled-components";

const Container = styled(Card)`
    width: 100%;
    height: 100%;
    padding: 20px !important;
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);

    @media screen and (max-width: 767px) {
        /* min-height: 72vh; */
        .ant-card-head {
            display: flex;
            justify-content: center;
        }
        .ant-card-head-wrapper {
            flex-direction: column;
        }
        /* .ant-card-body {
            height: 70vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: auto;
        } */
    }

    @media screen and (max-width: 360px) {
        /* min-height: 80vh; */
    }

    .contentDevices {
        display: flex;
        justify-content: center;
        align-items: center;
        /* width: 100%; */
        /* height: 40vh; */
        /* overflow: auto; */
        visibility: visible;
        transition: visibility 1s ease-in-out;

        /* @media screen and (max-width: 767px) {
            height: auto;
        } */
    }

    .slick-dots {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .slick-dots li {
        position: relative;
        display: inline-block;
        flex: 0 1 auto;
        box-sizing: content-box;
        width: 16px;
        height: 3px;
        margin: 0 2px;
        margin-right: 3px;
        margin-left: 3px;
        padding: 0;
        text-align: center;
        text-indent: -999px;
        vertical-align: top;
        transition: all 0.5s;
    }

    .slick-dots li button {
        display: block;
        width: 100%;
        height: 3px;
        padding: 0;
        color: transparent;
        font-size: 0;
        background: green;
        border: 0;
        border-radius: 1px;
        outline: none;
        cursor: pointer;
        opacity: 0.3;
        transition: all 0.5s;
        background: grey;
    }

    .previusPageIcon {
        position: absolute;
        top: 50%;
        right: 95%;
        border-radius: 50%;
        &:hover {
            color: green !important;
        }
        @media screen and (max-width: 767px) {
            top: 55%;
            right: 95%;
        }
        @media screen and (min-width:768) and (max-width: 991px) {
            right: 96.5%;
        }
    }

    .nextPageIcon {
        position: absolute;
        top: 50%;
        left: 95%;
        border-radius: 50%;
        &:hover {
            color: green !important;
        }
        @media screen and (max-width: 767px) {
            top: 55%;
            left: 95%;
        }
        @media screen and (min-width:768) and (max-width: 991px) {
            left: 96.5%;
        }
    }

    .ant-card-head-title {
        font-size: 25px;
    }

    .ant-card-head-wrapper {
        align-items: baseline !important;
    }

    .emptyDevices {
        display: flex !important;
        justify-content: center;
        align-items: flex-start;
        color: grey;
    }

    .emptyDevices span {
        font-size: 30px;
    }

    .emptyDevices h2 {
        margin-left: 10px;
        color: grey;
    }

    .addDeviceButton {
        border-radius: 50px;
        border: 1px solid green !important;
        color: green;
        padding-top: 5px;
    }
`

export default Container;