/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import BR from 'antd/es/locale/pt_BR';

import Root from './pages';
import GlobalStyle from './styles/global';
import AppProvider from './hooks';

import 'react-toastify/dist/ReactToastify.min.css';
import 'antd/dist/antd.css';

import ENV from './url_config';

const { prefixRoute } = ENV;

function App() {
  return (
    <ConfigProvider locale={BR}>
      <AppProvider>
        <BrowserRouter basename={prefixRoute || ''}>
          <Root />
          <GlobalStyle />
          <ToastContainer autoClose={10000} />
        </BrowserRouter>
      </AppProvider>
    </ConfigProvider>
  );
}

export default App;
