import { Button, Modal } from 'antd';
import React, { SetStateAction } from 'react';

type TAuthenticationModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<SetStateAction<boolean>>;
};

function ChangeAuthenticationModal({
  isModalOpen,
  setIsModalOpen,
}: TAuthenticationModalProps) {
  const onAgree = () => {
    sessionStorage.setItem('AgreeWithAuthenticationChange', 'true');
    setIsModalOpen(false);
  };
  const agreeStyle = {
    border: 'none',
    width: '30%',
    background: '#00853b',
    color: 'white',
    marginRight: '10px',
    borderRadius: '30px',
    fontSize: '1rem',
    fontWeight: '600',
  };
  return (
    <Modal
      title={
        <h2 style={{ fontWeight: 'bold', color: 'red', textAlign: 'center' }}>
          Aviso Importante
        </h2>
      }
      footer={
        <div style={{ textAlign: 'center' }}>
          <Button type="primary" onClick={onAgree} style={agreeStyle}>
            Entendi
          </Button>
        </div>
      }
      visible={isModalOpen}
      closable={false}
      destroyOnClose
    >
      <p style={{ fontSize: '1rem' }}>
        Em breve, o acesso a aplicação Meu Celular será pela plataforma Acesso
        Cidadão. Caso ainda não possua cadastro, clique{' '}
        <a href="https://acesso.cearadigital.ce.gov.br/login/" target="_blank">
          aqui
        </a>
        .
      </p>
    </Modal>
  );
}

export default ChangeAuthenticationModal;
