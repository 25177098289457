import { Card } from 'antd';
import styled from 'styled-components';

export const Container = styled(Card)`
  height: 100%;
  width: 100%;
  padding: 20px !important;
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .btnBack {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    width: 20% !important;
    background: orange !important;
    color: white !important;
    margin-right: 10px !important;
    height: 40px !important;
    border-radius: 50px;
    margin-left: 40%;
    margin-top: 20px;
  }

  @media screen and (max-width: 420px) {
    .ant-card-head-wrapper {
      flex-direction: column;
      text-align: center !important;
    }

    .ant-card-extra {
      margin-left: initial;
    }

    .ant-btn > .anticon + span,
    .ant-btn > span + .anticon {
      display: none;
    }
  }

  .ant-card-head-title {
    font-size: 25px;
  }

  .contentDetails {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .downloadButton {
    border-radius: 50px;
    border: 1px solid green !important;
    color: green;
    float: right;
  }

  .undoAlertButton {
    border-radius: 50px;
    border: 1px solid red !important;
    color: red;
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .undoAlertButtonDisabled {
    border-radius: 50px;
    border: 1px solid gray !important;
    color: gray;
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .gridStyle {
    flex-wrap: wrap;
    overflow: auto;
    display: flex;
    flex-direction: row;
    padding: 24px;
    border: 0;
    border-radius: 0;
    box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0,
      1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
    transition: all 0.3s;
    width: 50%;
    @media screen and (max-width: 767px) {
      width: 100%;
      flex-direction: column;
      text-align: center;
      flex-wrap: nowrap;
    }
  }

  .gridStyle span {
    font-size: 30px;
    color: green;
  }

  .gridStyle p {
    padding: 5px;
    font-size: 1.1em;
  }

  .ant-pagination-item-active {
    border-color: green;
  }

  .ant-pagination-item-active a {
    color: green;
  }
`;
