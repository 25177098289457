import { Card } from "antd";
import styled from "styled-components";

export const CardStyled = styled(Card)`
    width: 100%;
    height: 100%;
    padding: 20px !important;
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);

    h1 {
        font-size: 2.5rem;
    }

    .contentTitle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
        color: green;
    }

    .contentTitle h1 {
        margin-right: 10px;
        color: green;
    }

    h2 {
        font-size: 1.5rem;
    }

    p {
        font-size: 1.1em;
        text-align: justify;
    }

    span {
        color: green;
        margin-right: 10px;
        font-size: 22px;
    }

    .contentBody {
        display: flex;
        flex-direction: column;
        padding-top: 25px;
    }

    @keyframes showAnimation {
        from {
            opacity: 0.1;
        }
        to {
            opacity: 1;
        }
    }

    .contentElement {
        /* text-align: center; */
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        gap: 35px;
        transition: all 0.5s ease-out;
        animation-name: showAnimation;
        animation-duration: 1s;
    }

    .contentElement div {
        width: 70%;
    }

    .contentElement img {
        width: 300px;
    }

    /* .about {
        display: flex;
        flex-direction: row;
    } */

    /* .about div {
        width: 65%;
    } */

    .mission {
        text-align: right;
    }

    .security {
        text-align: right;
    }

    /* .mission div {
        width: 65%;
    } */

    .contentTitle img {
        transition: all 0.5s ease-out;
        animation-name: showAnimation;
        animation-duration: 1s;
    }

    @media screen and (max-width: 767px) {
        min-height: 72vh;

        .contentTitle {
            text-align: center;
        }

        .contentTitle h1 {
            font-size: 24px;
        }

        .contentElement {
            flex-direction: column;
            text-align: center;
            align-items: center;
        }

        .contentElement div {
            width: 100%;
        }

        .contentElement img {
            width: 250px;
        }

        /* .about img {
            width: 100% !important;
        } */

        .mission {
            flex-direction: column-reverse;
        }

        .mission div p {
            text-align: justify !important;
        }

        /* .tutorial a img {
            width: 70% !important;
        } */

        .security {
            flex-direction: column-reverse;
            align-items: center;
        }

    }

`;