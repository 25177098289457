export const secretsQuestions = [
  {
    label: 'Qual é o nome do seu melhor amigo de infância?',
    value: 'Qual é o nome do seu melhor amigo de infância?',
  },
  {
    label: 'Qual é o nome do seu primeiro animal de estimação?',
    value: 'Qual é o nome do seu primeiro animal de estimação?',
  },
  {
    label: 'Qual é o nome da sua avó paterna?',
    value: 'Qual é o nome da sua avó paterna?',
  },
  {
    label: 'Qual é o nome da rua onde você cresceu?',
    value: 'Qual é o nome da rua onde você cresceu?',
  },
  {
    label: 'Qual é o seu prato de comida favorito?',
    value: 'Qual é o seu prato de comida favorito?',
  },
  {
    label: 'Qual é o nome da sua primeira escola?',
    value: 'Qual é o nome da sua primeira escola?',
  },
  {
    label: 'Qual é a sua música favorita?',
    value: 'Qual é a sua música favorita?',
  },
  {
    label: 'Qual é o nome do seu time esportivo favorito?',
    value: 'Qual é o nome do seu time esportivo favorito?',
  },
  {
    label: 'Qual é o nome do seu personagem fictício favorito?',
    value: 'Qual é o nome do seu personagem fictício favorito?',
  },
  {
    label: 'Qual é a cidade onde seus pais se conheceram?',
    value: 'Qual é a cidade onde seus pais se conheceram?',
  },
];
