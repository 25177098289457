import { LeftOutlined } from "@ant-design/icons";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 10%;
    color: ${props => props.color};

    :hover {
        transform: scale(1.1);
        transition: all 0.4s ease-out;
        box-shadow: none !important;
    }

    .content-text {
        font-size: 1.1rem;
        cursor: pointer;
    }
`;

export const Button = styled(LeftOutlined)`
    background-color: transparent;
    /* color: ${props => props.color}; */
    font-size: 20px;
    margin-right: 5px;
    cursor: pointer;    
`;