import styled from 'styled-components';

export const Profile = styled.div`
  display: flex;
  align-items: center;
  border-left: 2px solid #fff;
  height: 30px;
  text-align: center;

  #userName {
    display: block;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    padding-left: 20px;
  }

  div {
    text-align: right;
    margin-right: 10px;

    a {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      color: #999;
    }
  }
`;

export const Container = styled.div`
  .logoName {
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    padding-right: 5px;
    /* border-right: 1px solid #fff; */

    width: 490px;

    @media screen and (max-width: 1620px) {
      font-size: 1.6rem;
      width: 420px;
    }

    @media screen and (max-width: 1520px) {
      width: 450px;
    }

    @media screen and (max-width: 1220px) {
      width: 470px;
    }

    @media screen and (max-width: 820px) {
      font-size: 1.5rem;
      border-right: 0px;
      width: 230px;
    }

    @media screen and (max-width: 420px) {
      display: none;
    }
  }

  .logoNameIsNotLogged {
    font-size: 1.6em;
    font-weight: 700;
    color: #fff;
    padding-right: 5px;
    border-right: 1px solid #fff;

    @media screen and (max-width: 420px) {
      display: none;
    }
  }

  .logoNameSecretaria {
    /* width: 100%; */
    /* /* font-size: 1.08rem; */
    font-weight: bold;
    /* line-height: 15px; */
    padding: 10px;
    padding-top: 25px;
    /* margin-left: 10px; */

    @media screen and (max-width: 767px) {
      height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: 820px) {
      display: none;
    }

    pre {
      color: #fff;
      font-family: sans-serif;
    }

    b {
      color: #fff;
      font-size: 1.125rem;
      line-height: 1.75rem;
      @media screen (max-width: 1024px) {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }

    p {
      color: #fff;
      font-size: 0.875rem;
      line-height: 1.25rem;
      @media screen (max-width: 1024px) {
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }
  }

  .labelEco {
    @media (max-width: 50em) {
      display: none !important;
    }
  }

  .logosEco svg {
    min-width: 26px;
    min-height: 27px;
  }

  .labelEcoMobile {
    @media (min-width: 50em) {
      display: none !important;
    }
  }

  .logosEco {
    @media (max-width: 50em) {
      margin: 0 auto !important;
    }
  }

  .governo {
    display: flex !important;
  }

  .profile {
    display: flex;
    /* width: 100%; */
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 50em) {
      // display: none;
    }
  }

  .menu_browser {
    @media (max-width: 50em) {
      display: none;
    }
  }

  .menu_mobile {
    /* @media (min-width: 50em) {
      display: none;
    } */
  }

  .searchable {
    @media (max-width: 72em) {
      display: none;
    }
  }

  body {
    background: #e9e9e9;
    font-family: sans-serif;
    margin: 0px;
    padding: 0px;
  }

  .standard_layout .content_contain {
    overflow: hidden;
    background: #e9e9e9;
  }

  .mark {
    margin-right: 1.25em;
  }

  @media (min-width: 50em) {
    .mark {
      display: none !important;
    }
  }

  .nav_contain {
    display: block;
    padding: 0.75em 0;
    text-align: center;
    ul {
      @media (min-width: 72em) {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 0;
        margin: 0;
      }

      li {
        display: block;
        padding: 0.75em 0;
        text-align: center;
        a {
          position: relative;
          display: block;
          color: #090910;
          text-decoration: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        @media (min-width: 72em) {
          position: relative;
          margin-right: 8.5%;
          border-bottom: none;
        }
      }
    }

    @media (min-width: 72em) {
      max-height: 100vh;
      overflow: visible;
      opacity: 1;
      padding: 0;
      -webkit-transform: none;
      transform: none;
      margin-right: 300px;
    }
  }

  /* HEADER */

  header {
    position: relative;
    z-index: 1000;
    -webkit-transition: padding 0.3s ease;
    transition: padding 0.3s;

    /* height: 110px; */
    width: 100%;

    display: flex;
    align-items: center;
    background-color: rgba(240, 240, 240, 0.8);

    .contentMenu {
      display: flex;
      flex-direction: row;
      gap: 3rem;
      padding-top: 1rem;
      align-items: baseline;
      @media screen and (max-width: 620px) {
        display: none;
      }
    }

    .contentMenu a {
      cursor: pointer !important;
    }

    .contentMenu p {
      /* border-right: 2px solid rgb(74 222 128);
      padding-right: 5px; */
      margin-top: 0;
      margin-bottom: 1em;
      margin-right: 15px;
      color: #000;
      /* color: rgb(108, 117, 125); */
      font-size: 1.2rem;
      cursor: pointer;
      &:hover {
        color: green;
      }
    }

    .content-menu-icon {
      cursor: pointer;
      @media screen and (min-width: 620px) {
        display: none;
      }
    }

    .greenBar {
      background: linear-gradient(to right, #24a637, #56bfc9 95%);
      padding: 1rem;
      width: 100%;
      height: 6rem;
      margin: 0;
      display: flex;
      justify-content: space-between;
      /* padding: 15px;
      padding-top: 35px; */
      align-items: center;
      cursor: pointer;
    }

    .greenBarNotLogged {
      background: linear-gradient(to right, #24a637, #56bfc9 95%);
      padding: 1rem;
      width: 100%;
      height: 6rem;
      margin: 0;
      display: flex;
      justify-content: space-between;
      /* padding: 15px;
      padding-top: 35px; */
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        justify-content: center;
      }
    }

    .contain {
      /* padding: 0 1em;
      max-width: 80em; */
      /* overflow-x: hidden; */
      width: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 85px;
      box-shadow: 0 10px 15px -8px rgba(9, 9, 16, 0);
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-transition: box-shadow 0.6s ease;
      transition: box-shadow 0.6s ease;
    }

    /* .logoImg {
      max-width: 348px;
      height: 64px;
    }

    @media (max-width: 620px) {
      .logoImg {
        max-width: 45px;
        max-height: 61px;
      }
    } */

    .content-logos {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .content-logo {
      color: #fff;
      font-size: 1.5rem;
      font-weight: bold;

      @media screen and (max-width: 420px) {
        display: none;
      }
    }

    .content-logo-not-logged {
      color: #fff;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .content-sspds-logo {
      display: flex;
      align-items: center;
    }

    .content-logo-bottom {
      display: flex;
      width: 100%;
      background-repeat: no-repeat;
      height: max-content;
    }

    .content-logo-bottom img {
      height: max-content;
    }

    .logoImg {
      padding-left: 5px;
      max-width: 348px;
      height: 64px;
    }

    @media (max-width: 620px) {
      .logoImg {
        max-width: 45px;
        max-height: 61px;
        margin-right: 20px;
      }
    }

    @media (max-width: 375px) {
      .ant-avatar-lg {
        width: 3em;
        height: 3em;
      }
    }
    .header_content_menu {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: none;
      align-items: center;
      background-color: #fff;
      border-bottom: 3px solid #00bd19;
      padding: 1rem;
      justify-content: end;
      height: 2rem;

      /* @media screen and (max-width: 767px) {
        justify-content: end;
      } */
    }

    @media (max-width: 548px) {
      /* .baseOndas {
        display: none;
      } */
      .header_content_menu {
        justify-content: end;
      }
    }

    @media (max-width: 768px) {
      .baseOndas {
        top: 102%;
      }
    }

    @media (min-width: 768px) {
      .baseOndas {
        top: 105%;
      }
    }

    @media (min-width: 1024px) {
      .baseOndas {
        position: relative;
      }
      /* .greenBar {
        padding-top: 30px;
      } */
    }

    .page_contain {
      max-width: 100em;
      margin: 0 auto;
      padding: 0 5.5%;
      margin-top: -150;
    }

    .search_box .input_group {
      padding: 0;
      width: 100%;
      -webkit-transition: width 0.5s ease;
      transition: width 0.5s ease;
      margin-right: 30;
    }
  }
  .baseOndas {
    width: 100%;
    background-repeat: no-repeat;
    height: max-content;
    top: 98%;
    position: absolute;
    /* padding-top: 15px; */
  }

  .baseOndas img {
    height: 100%;
  }
`;

export const MenuMobile = styled.div`
  width: 65vw;
  height: 100vh;
  background: #fff;
  position: fixed;
  z-index: 1001;
  right: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 2s;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  animation-name: showMenu;
  animation-duration: 0.5s;

  @keyframes showMenu {
    from {
      width: 0;
    }
    to {
      width: 65vw;
    }
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 10%;
  }

  ul li {
    font-size: 1.2em;
  }

  a {
    color: grey;
    cursor: pointer;
  }

  a:hover {
    color: green;
  }

  .close-btn {
    float: right;
    padding: 3px;
    cursor: pointer;
  }

  .content-user {
    display: flex;
    margin-top: 20%;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: 100%;
  }

  .content-user span {
    font-size: 1.2em;
  }

  @media screen and (min-width: 620px) {
    display: none;
  }
`;
