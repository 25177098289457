import { Modal } from 'antd';
import styled from 'styled-components';

export const AddUserInfoModalContainer = styled(Modal)`
  width: 75% !important;
  max-width: 700px;
  top: 50px !important;

  .content-title {
    font-weight: bold !important;
    text-align: center !important;
  }

  .content-footer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: end;
  }

  .content-footer button {
    min-width: 200px;
    width: 30%;
  }

  .content-requirements {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0.5rem;
    pointer-events: none;
    padding-left: 1rem;
  }

  @media screen and (max-width: 640px) {
    .content-footer {
      flex-direction: column-reverse;
    }
    .content-footer button {
      width: 100%;
      margin: 0 !important;
    }
  }
`;
