import { Upload } from 'antd';
import styled from 'styled-components';

export const UploadContainer = styled(Upload)`
  .ant-btn-text {
    background: white !important;
    border: none !important;
    color: red !important;
  }

  .ant-upload-text-icon > .anticon {
    color: green !important;
  }
`;
