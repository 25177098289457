import CryptoJS from 'crypto-js';

export const useEncription = (data, type) => {
  try {
    const secret = `${process.env.REACT_APP_SECRET}`;
    if (type === 'encrypt') {
      // Função para criptografar dados
      return CryptoJS.AES.encrypt(data, secret).toString();
    } else {
      // Função para descriptografar dados
      const bytes = CryptoJS.AES.decrypt(data, secret);
      return bytes.toString(CryptoJS.enc.Utf8);
    }
  } catch {
    return null;
  }
};
