import {
  Button,
  Card,
  Collapse,
  Divider,
  Form,
  Input,
  Modal,
  Spin,
  Table,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Container } from './style';
import cellphoneService from '../../services/cellphone';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { dataHistoryColumns } from './columns';
import {
  AlertOutlined,
  HourglassOutlined,
  MobileOutlined,
  RollbackOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import { DeviceDetailsProps, Imei } from 'interfaces/history';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';

export default function DeviceDetails() {
  const [deviceDetails, setDeviceDetails] = useState<DeviceDetailsProps | null>(
    null
  );
  const [loading, setLoading] = useState({
    historyRequest: false,
    download: false,
    undoAlert: false,
  });
  const { id } = useParams();
  const { Panel } = Collapse;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const updateDeviceDetails = useCallback((newProperties: any) => {
    setDeviceDetails(prev => ({ ...prev, ...newProperties }));
  }, []);

  const updateLoading = useCallback((newProperties: any) => {
    setLoading(prev => ({ ...prev, ...newProperties }));
  }, []);

  const initFetch = useCallback(
    async (isMounted: boolean) => {
      try {
        updateLoading({ historyRequest: true });
        if (!isMounted) {
          const historyResponse = await cellphoneService().cellphoneHistory(id);
          const statusAlert =
            historyResponse.statusAlerta === 'Sem Alerta'
              ? 'Sem Alerta'
              : historyResponse.statusAlerta === 'Pre Alerta'
              ? 'Pré Alerta'
              : 'Em Alerta';
          updateDeviceDetails({
            data: {
              fabricante: historyResponse.fabricante,
              id: historyResponse.id,
              modelo: historyResponse.modelo,
              numeroDeSerie: historyResponse.numeroDeSerie,
              statusAlerta: statusAlert,
              dataPreAlerta: historyResponse.ocorrencias[0].dataLimitePreAlerta,
            },
            imeis: historyResponse.imeis,
            ocorrencias: historyResponse.ocorrencias,
          });
        }
      } catch (err) {
        console.error(err);
        toast.error('Erro ao carregar dados do dispositivo');
      } finally {
        updateLoading({ historyRequest: false });
      }
    },
    [id]
  );

  const onDownload = useCallback(async () => {
    try {
      updateLoading({ download: true });
      const fileToDownload = await cellphoneService().getDocumentFromId(id);
      const { data, headers } = fileToDownload;
      if (data.byteLength > 0) {
        const type = headers['content-type'];
        const binaryData = [];
        binaryData.push(data);

        const fileUrl = URL.createObjectURL(new Blob(binaryData, { type }));
        const downloadLink = document.createElement('a');
        const dotType = type.includes('jpeg')
          ? 'jpeg'
          : type.includes('png')
          ? 'png'
          : type.includes('pdf') && 'pdf';
        const fileName = `Nota.${dotType}`;

        downloadLink.href = fileUrl;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        toast.warning('Dispositivo sem nota fiscal associado');
      }
    } catch (err) {
      console.error(err);
      toast.warning('Erro ao baixar nota fiscal');
    } finally {
      updateLoading({ download: false });
    }
  }, [id]);

  const onUndoAlert = useCallback(
    async (values: any) => {
      try {
        updateLoading({ undoAlert: true });
        await cellphoneService().rollbackCellphoneStatus(id, values);
        toast.success('Ultimo alerta desfeito');
        setIsModalOpen(false);
        form.resetFields();
        initFetch(false);
      } catch (err) {
        console.error(err);
        toast.error('Erro ao desfazer ultimo alerta');
      } finally {
        updateLoading({ undoAlert: false });
      }
    },
    [id]
  );

  useEffect(() => {
    let isMounted = false;
    initFetch(isMounted);

    return () => {
      isMounted = true;
    };
  }, []);

  //função para pegar o último alerta que não tenha a flag cancelada
  const checkLastStatus = () => {
    const size = deviceDetails?.ocorrencias?.length;
    const historyData = deviceDetails?.ocorrencias;
    if (size && historyData) {
      for (let i = 0; i <= size; i++) {
        if (!historyData[i].flagCancelado) {
          return historyData[i];
        }
      }
    }
  };

  const renderStatusAlert = (value: any) => {
    switch (value) {
      case 'Sem Alerta':
        return (
          <div className="gridStyle">
            <AlertOutlined style={{ color: '#228b22' }} />
            <p>
              <b> Estado atual: </b> {value}{' '}
            </p>
          </div>
        );
      case 'Em Alerta':
        return (
          <div className="gridStyle">
            <AlertOutlined style={{ color: '#db5d5d' }} />
            <p>
              <b> Estado atual: </b> {value}{' '}
            </p>
          </div>
        );
      case 'Pré Alerta':
        return (
          <div className="gridStyle">
            <AlertOutlined style={{ color: '#ff9b05' }} />
            <p>
              <b> Estado atual: </b> {value}{' '}
            </p>
          </div>
        );
      default:
        return (
          <div className="gridStyle">
            <AlertOutlined />
            <p>
              <b> Estado atual: </b> {value}{' '}
            </p>
          </div>
        );
    }
  };

  return (
    <Container
      title="Detalhes"
      extra={
        <Button
          className="downloadButton"
          onClick={onDownload}
          loading={loading.download}
        >
          Baixar nota fiscal
        </Button>
      }
    >
      <Spin spinning={loading.historyRequest}>
        <div className="contentDetails">
          <div className="gridStyle">
            <MobileOutlined />
            <p>
              <b> Marca: </b> {deviceDetails?.data.fabricante}
            </p>
            <p>
              <b> Modelo: </b> {deviceDetails?.data.modelo}
            </p>
          </div>

          {renderStatusAlert(deviceDetails?.data.statusAlerta)}

          <div className="gridStyle">
            <ScheduleOutlined />
            <p>
              <b> Número de série: </b>{' '}
              {deviceDetails?.data.numeroDeSerie || '---'}
            </p>
            <p>
              <b>Imei(s):</b>
              {deviceDetails?.imeis.map((imei: Imei, index: number) => {
                return index >= 1
                  ? ` / ${imei.numeroImei}`
                  : ` ${imei.numeroImei}`;
              })}
            </p>
          </div>
          {checkLastStatus()?.statusAlerta === 'PRE_ALERTA' && (
            <div className="gridStyle">
              <>
                <HourglassOutlined />
                <p>
                  <b>Data de expiração do pre-alerta: </b>
                  {deviceDetails?.data.dataPreAlerta
                    ? moment(
                        deviceDetails?.data?.dataPreAlerta,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format('DD/MM/YYYY HH:mm:ss')
                    : ''}
                </p>
              </>
            </div>
          )}
        </div>
        <Divider />
        <Collapse defaultActiveKey={['1']}>
          <Panel header={<h3>Histórico</h3>} key="1">
            <Button
              className={
                checkLastStatus()?.statusAlerta === 'SEM_ALERTA'
                  ? 'undoAlertButtonDisabled'
                  : 'undoAlertButton'
              }
              icon={<RollbackOutlined />}
              loading={loading.undoAlert}
              disabled={checkLastStatus()?.statusAlerta === 'SEM_ALERTA'}
              onClick={showModal}
            >
              Desfazer ultimo alerta
            </Button>
            <Table
              rowKey={'dataCriacao'}
              dataSource={deviceDetails?.ocorrencias}
              columns={dataHistoryColumns}
              style={{ overflow: 'auto' }}
            />
          </Panel>
        </Collapse>

        <Modal
          title="Desfazer  ultimo alerta"
          visible={isModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <Form form={form} onFinish={onUndoAlert}>
            <Form.Item name="justificativa">
              <TextArea placeholder="Justificar Cancelamento" />
            </Form.Item>
            <Button key="submit" type="primary" htmlType="submit">
              Confirmar
            </Button>
          </Form>
        </Modal>
      </Spin>
      <Button onClick={() => navigate('/home')} className="btnBack">
        <RollbackOutlined /> Voltar
      </Button>
    </Container>
  );
}
