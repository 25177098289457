import BackButton from 'components/backButton';
import React from 'react';
import { articles } from './articles';
import { AdequacyTermStyle, CardStyled } from './style';

function AdequacyTerm() {
  //   const onDowload = () =>
  //     fetch('/Termo-LGPD.pdf')
  //       .then(response => response.blob())
  //       .then(blob => {
  //         const url = URL.createObjectURL(blob);
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.download = 'file.pdf';
  //         link.click();
  //       });
  return (
    <AdequacyTermStyle>
      <CardStyled
        title={
          <div className="content-title">
            <BackButton />
            <h1 className="content-title-text">Termo de Adequação à LGPD</h1>
            {/* <Button onClick={onDowload} type="primary">
              <DownloadOutlined />
              <span>Baixar pdf</span>
            </Button> */}
          </div>
        }
      >
        <p className="content-text">
          O presente Termo de Adequação LGPD (“Termo”) tem como objetivo
          estabelecer as diretrizes e boas práticas que a Secretaria da
          Segurança Pública adotará, em conformidade com o disposto na Lei Geral
          de Proteção de Dados Pessoais (Lei nº 13.709/2018).
          <ul className="content-articles">
            {articles.map(article => (
              <li key={article.key}>
                {article.title}.{article.content}
              </li>
            ))}
          </ul>
        </p>
      </CardStyled>
    </AdequacyTermStyle>
  );
}

export default AdequacyTerm;
