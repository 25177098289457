import { UploadOutlined } from '@ant-design/icons';
import { Modal, Form, Input, Button, Spin, UploadProps } from 'antd';
import { ITalkToUsBody } from 'interfaces/auth';
import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { AuthService } from 'services/auth';
import {
  cpfMask,
  formatCellphone,
  getBase64,
  isValideCPF,
} from 'utils/functions';
import { UploadContainer } from './styles';

const { TextArea } = Input;

type TTalkToUsModal = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export const TalkToUsModal = ({ open, setOpen }: TTalkToUsModal) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { talkToUs } = AuthService();

  const cpfValidation = (_: any, value: string) => {
    if (value && isValideCPF(value)) {
      return Promise.resolve();
    }

    if (value) {
      return Promise.reject('CPF inválido');
    }
    return Promise.reject('Obrigatório');
  };

  const handleChangeCPF = useCallback((event: any) => {
    const masked = cpfMask(event.target.value);
    form.setFieldsValue({ cpf: masked });
  }, []);

  const handleCellphoneChange = (e: any) => {
    const { value } = e.target;
    const formattedValue = formatCellphone(value);

    form.setFieldsValue({
      cellphone: formattedValue,
    });
  };

  const handleCancelModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const onSubmit = useCallback(async (values: ITalkToUsBody) => {
    try {
      setLoading(true);

      let base64Img = '';

      if (values.uploadFile?.file) {
        base64Img = await getBase64(values.uploadFile.file.originFileObj);
      }

      const obj = {
        cpf: values.cpf,
        email: values.email,
        cellphone: values.cellphone,
        image: base64Img,
        title: values.title,
        description: values.description,
      };

      await talkToUs(obj);

      toast.success('Sua mensagem foi enviada');
    } catch (e) {
      console.log('err', e);
    } finally {
      form.resetFields();
      setLoading(false);
      setOpen(false);
    }
  }, []);

  const props: UploadProps = {
    name: 'file',
    action: '',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
    },
  };

  return (
    <Modal
      visible={open}
      destroyOnClose
      onCancel={handleCancelModal}
      title="Envie uma mensagem para nosso suporte"
      footer={null}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={onSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Form.Item
            label="CPF"
            name="cpf"
            rules={[
              {
                required: true,
                validator: cpfValidation,
              },
            ]}
            style={{
              width: '100%',
            }}
          >
            <Input placeholder="000.000.000-00" onChange={handleChangeCPF} />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
              },
            ]}
            style={{
              width: '100%',
            }}
          >
            <Input type="email" placeholder="email@email.com" />
          </Form.Item>

          <Form.Item
            // className={styles.inputStyle}
            name="cellphone"
            label="Telefone"
            rules={[
              {
                required: true,
                message: 'Obrigatório',
              },
            ]}
            style={{
              width: '100%',
            }}
          >
            <Input
              required
              // className={styles.input}
              name="telefone"
              type="tel"
              placeholder="(00)00000-0000"
              onChange={e => handleCellphoneChange(e)}
              maxLength={14}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>

          <Form.Item
            // className={styles.inputStyle}
            name="title"
            label="Título"
            rules={[
              {
                required: true,
                message: 'Obrigatório',
              },
            ]}
            style={{
              width: '100%',
            }}
          >
            <Input
              required
              // className={styles.input}
              name="telefone"
              type="tel"
              style={{
                width: '100%',
              }}
            />
          </Form.Item>

          <Form.Item
            name="description"
            style={{
              width: '100%',
            }}
            rules={[
              {
                validator: (_, value) => {
                  let valueT = value?.trim();
                  if (
                    !valueT ||
                    valueT.trim().length < 5 ||
                    valueT.length > 500
                  ) {
                    return Promise.reject(
                      'O texto deve possuir entre 5 e 500 caracteres'
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <TextArea rows={4} placeholder="Escreva sua mensagem aqui" />
          </Form.Item>
          <Form.Item
            name="uploadFile"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <UploadContainer {...props} accept="image/jpg" maxCount={1}>
              <Button htmlType="button" icon={<UploadOutlined />}>
                Adicione uma imagem
              </Button>
            </UploadContainer>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ borderRadius: '5px', height: '40px', width: '200px' }}
            >
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
