import { useState } from 'react';
import { Collapse } from 'antd';

import { toast } from 'react-toastify';
import Wrapper from './styles';
import 'react-toastify/dist/ReactToastify.min.css';

import devPrint from '../../utils/devPrint';
import ImgSSPDS from '../../assets/duvidasFrequentres.svg';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import BackButton from 'components/backButton';
import TalkToUsIcon from 'assets/talk-to-us.png';
import { TalkToUsModal } from 'components/modals/fale-conosco';

toast.configure();
export default function PerguntasFrequentes() {
  const { Panel } = Collapse;
  const [openTalkToUsModal, setOpenTalkToUsModal] = useState(false);

  function callback(key) {
    devPrint(key);
  }

  const handleOpenTalkToUsModal = () => {
    setOpenTalkToUsModal(true);
  };

  return (
    <Wrapper className="wrapper">
      <div className="container">
        <BackButton page="" />
        <div className="containerImg">
          <img
            src={ImgSSPDS}
            alt="SSPDS"
            /* style={{ height: 200, width: 150 }} */
          />
        </div>
        <Collapse defaultActiveKey={['']} onChange={() => callback}>
          <Panel
            className="title"
            header="Como entrar em contato com o suporte?"
            key="16"
          >
            <p>
              Em caso de dúvidas entrar em contato pelo email
              meucelular@sspds.ce.gov.br. Por favor, forneça seu CPF, telefone,
              e-mail e detalhes sobre o problema que está enfrentando.
            </p>
          </Panel>
          <Panel
            className="title"
            header="Meu dispositivo será rastreado?"
            key="0"
          >
            <p>Não, o seu dispositivo não será rastreado.</p>
          </Panel>
          <Panel
            className="title"
            header="O que é o sistema Meu Celular? Como ele funciona?"
            key="1"
          >
            <p>
              O Meu Celular é uma plataforma online que possibilita aos cidadãos
              cadastrar o IMEI (Identidade Internacional de Equipamento Móvel)
              de um ou mais telefones celulares de sua propriedade. Em situações
              de furto, roubo, perda ou extravio, é viável inserir um ALERTA
              específico para o aparelho, condicionando a permanência desse
              ALERTA à posterior realização do procedimento policial, dentro de
              até 72 horas após a criação do alerta. Em caso de recuperação, o
              cidadão será notificado por e-mail para reaver o dispositivo,
              mediante apresentação da nota fiscal ou documento idôneo que
              comprove a propriedade do mesmo.
            </p>
          </Panel>
          <Panel
            className="title"
            header="Por que um site e não um aplicativo?"
            key="2"
          >
            <p>
              Para garantir maior acessibilidade, o sistema pode ser acessado
              através do navegador de internet em um computador, celular ou
              tablet, sem a necessidade de baixar ou instalar um aplicativo.
            </p>
          </Panel>
          <Panel
            className="title"
            header="De que forma o sistema Meu Celular vai ajudar o cidadão?"
            key="3"
          >
            <p>
              Além da função reparadora, que possibilita a devolução do
              dispositivo ao seu proprietário, espera-se que o sistema Meu
              Celular desestimule a posse de celulares de procedência ilícita,
              por consequência a redução dos crimes que tem como objeto de
              desejo o aparelho celular.
            </p>
          </Panel>
          <Panel
            className="title"
            header="Fazer o cadastro vai impedir que ele seja roubado?"
            key="4"
          >
            <p>
              Não. Porém, caso isso aconteça, o cadastro e o registro do alerta
              no Portal facilitará a devolução do dispositivo se este for
              encontrado pela Polícia.
            </p>
          </Panel>
          {/* <Panel
            className="title"
            header="O que preciso fazer para realizar o cadastro?"
            key="5"
          >
            <p>
              Ser maior de 18 anos e possuir documento idôneo que comprove a
              propriedade do aparelho.
            </p>
          </Panel> */}
          <Panel
            className="title"
            header="De que maneira a autoridade policial poderá identificar que o celular pertence a mim?"
            key="6"
          >
            <p>
              Através das informações registradas na plataforma, dados como
              IMEI, modelo e nome do proprietário podem ser fundamentais para
              auxiliar as autoridades policiais na localização e identificação
              do dono do dispositivo.
            </p>
          </Panel>
          {/* <Panel
            className="title"
            header="
            Há alguma chance de recuperar meu celular se ele foi roubado antes de ser registrado no Portal?"
            key="7"
          >
            <p>
              Sim, o cadastro no Portal pode ser realizado a qualquer momento.
              Se o cidadão já tiver feito o registro de ocorrência (BO) por
              furto, roubo, perda ou extravio, o celular será restrito na base
              de dados da SSPDS. Ao cadastrar o mesmo IMEI do BO no portal, os
              registros serão automaticamente integrados. Portanto, caso a
              Polícia recupere o referido telefone, o cidadão será notificado
              sobre a disponibilidade do aparelho para devolução.
            </p>
          </Panel> */}
          <Panel
            className="title"
            header="Como ocorre a devolução do meu dispositivo, caso seja encontrado?"
            key="8"
          >
            <p>
              O cidadão receberá uma notificação por e-mail indicando a
              delegacia onde o dispositivo está disponível para devolução. Para
              recuperar o dispositivo, será necessário apresentar a nota fiscal
              ou um documento válido que comprove a propriedade do mesmo.
            </p>
          </Panel>
          <Panel
            className="title"
            header="Quais os cuidados devo tomar antes de comprar um celular usado?"
            key="9"
          >
            <p>
              É essencial garantir que o dispositivo não possua restrições, seja
              por órgãos como a Anatel ou através do Meu Celular. Além disso, é
              recomendável solicitar a nota fiscal do mesmo ao vendedor.
            </p>
          </Panel>
          <Panel
            className="title"
            header="Um mesmo dispositivo pode ser registrado duas vezes no sistema?"
            key="10"
          >
            <p>
              Não é possível cadastrar o mesmo número de IMEI mais de uma vez no
              sistema.
            </p>
          </Panel>
          <Panel
            className="title"
            header="Posso cadastrar mais de um celular?"
            key="11"
          >
            <p>Sim. Não há limitação de quantidade de aparelhos.</p>
          </Panel>
          <Panel
            className="title"
            header="Os celulares roubados antes do lançamento do Meu celular também podem ser cadastrados?"
            key="12"
          >
            <p>
              Sim, entretanto, o cidadão deverá vincular um procedimento
              policial ao alerta.
            </p>
          </Panel>
          <Panel
            className="title"
            header="Qual é a consequência caso opte por não divulgar o número de IMEI durante uma abordagem policial?"
            key="13"
          >
            <p>
              O policial analisará a situação e, se houver uma suspeita
              justificada de que a pessoa esteja carregando um dispositivo de
              origem duvidosa, a pessoa pode ser encaminhada à delegacia para
              uma investigação mais aprofundada.
            </p>
          </Panel>
          <Panel
            className="title"
            header="É possível que uma pessoa seja detida se optar por não fornecer o número do IMEI durante uma abordagem policial? Em caso afirmativo, qual crime estaria sendo cometido?"
            key="14"
          >
            <p>
              De acordo com o que é previsto na Constituição, ninguém pode ser
              compelido a produzir provas contra si mesmo. Dessa forma, se o
              cidadão se recusar a fornecer o número de IMEI para verificação, o
              policial analisará se é necessário encaminhá-lo à delegacia de
              Polícia para investigar os indícios ou fatos que fundamentam a
              suspeita, respeitando os princípios constitucionais aplicáveis à
              situação.
            </p>
          </Panel>
          <Panel
            className="title"
            header="Estão planejadas operações específicas para apreender celulares roubados? Qual é o procedimento de abordagem da polícia nesses casos?"
            key="15"
          >
            <p>
              O sistema Meu Celular se une a outras ferramentas já empregadas
              pelas forças de segurança do Estado, como sistemas de
              reconhecimento facial e de placas de veículos, com o intuito de
              aprimorar a eficácia na prevenção e combate aos crimes
              patrimoniais. Essa ferramenta será empregada rotineiramente
              durante as atividades policiais, sempre que necessário. O policial
              realizará a verificação do dispositivo por meio da leitura do QR
              code ou código de barras do IMEI do aparelho, sem acesso ao
              conteúdo do mesmo.
            </p>
          </Panel>
          {/* <Panel
            className="title"
            header="Qual é o procedimento recomendado caso alguém identifique a comercialização de um aparelho com restrição?"
            key="16"
          >
            <p>
              O Disque-Denúncia continuará disponível no número 181, oferecendo
              um serviço gratuito e ininterrupto. Para realizar denúncias via
              WhatsApp, utilize o número (85) XXXX-XXXX, onde é possível enviar
              áudios, vídeos, fotografias e documentos.
            </p>
          </Panel> */}
        </Collapse>
      </div>
      <div className="talkToUs" onClick={handleOpenTalkToUsModal}>
        <div className="iconContainer">
          <img src={TalkToUsIcon} style={{ width: '50px', height: '50px' }} />
        </div>
        <p className="text">Fale conosco</p>
      </div>
      <TalkToUsModal open={openTalkToUsModal} setOpen={setOpenTalkToUsModal} />
    </Wrapper>
  );
}
