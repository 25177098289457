/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  ReactNode,
} from 'react';
import { toast } from 'react-toastify';
import { apiAuth, AuthService } from '../services/auth';
import { AuthProps, SiaaData } from 'interfaces/auth';
import decodeJwt from 'utils/decodeJwt';
import ENV from '../url_config';
const { prefixRoute } = ENV;
// @ts-ignore
const AuthContext = createContext<AuthProps>();

// eslint-disable-next-line react/prop-types
function AuthProvider({ children }: { children: ReactNode }) {
  const { login } = AuthService();
  const [data, setData] = useState(() => {
    const token = sessionStorage.getItem('@SSPDS_ALERTA:token');
    const client = sessionStorage.getItem('@SSPDS_ALERTA:client');
    if (token && client) {
      // api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, client: JSON.parse(client) };
    }
    return {};
  });

  const signOut = useCallback(() => {
    sessionStorage.removeItem('@SSPDS_ALERTA:token');
    sessionStorage.removeItem('@SSPDS_ALERTA:client');
    sessionStorage.removeItem('hasAgreedWithLgpd');
    window.location.assign('/login');
    setData({});
  }, []);

  const signIn = useCallback(
    async ({ cpf, password }: { cpf: string; password: string }) => {
      try {
        const { token, client } = await login(cpf, password);
        if (token) {
          sessionStorage.setItem('@SSPDS_ALERTA:token', token || '');
          sessionStorage.setItem(
            '@SSPDS_ALERTA:client',
            JSON.stringify({
              name: client?.name,
              cpf: client?.cpf,
              cellphone: client?.cellphone,
            })
          );
          setData({
            token,
            client,
          });
          return 'isAuthenticated';
        } else {
          return 'isNotAuthenticated';
        }
      } catch (err: any) {
        console.error(err);
      }
    },
    []
  );

  return (
    <AuthContext.Provider
      value={{
        client: data?.client || { nome: '' },
        /* roles: data.roles, */
        token: data?.token,
        setData,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

let authTokenRequest: any;

function resetAuthTokenRequest() {
  authTokenRequest = null;
}

function getAuthToken() {
  if (!authTokenRequest) {
    authTokenRequest = apiAuth.post('/token');
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }
  return authTokenRequest;
}

export { AuthProvider, useAuth, getAuthToken };
