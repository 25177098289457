/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
function sumDig(n) {
  let a = 0;
  while (n > 0) {
    a += n % 10;
    // eslint-disable-next-line no-param-reassign
    n = parseInt(n / 10, 10);
  }
  return a;
}

function isValidIMEI(n) {
  // Converting the number into
  // String for finding length

  const s = n.toString();
  const len = s.length;

  if (len !== 15 && len !== 16) {
    return false;
  }

  let sum = 0;
  for (let i = len; i >= 1; i--) {
    let d = n % 10;

    // Doubling every alternate digit
    if (i % 2 === 0) {
      d *= 2;
    }

    // Finding sum of the digits
    sum += sumDig(d);
    n = parseInt(n / 10, 10);
  }

  return sum % 10 === 0;
}

export default isValidIMEI;
