import { CardStyled } from './style';
import {
  CheckCircleOutlined,
  ContainerOutlined,
  MobileOutlined,
  SecurityScanOutlined,
} from '@ant-design/icons';
import aboutIcon from '../../assets/SobreOALertaCelular.svg';
import tutorialIcon from '../../assets/ComoFunciona.svg';
import missionIcon from '../../assets/NossaMissao.svg';
// import logo from '../../assets/logoAlerta.png';
// import copIcon from '../../assets/copIcon.png';
// import copGetCriminous from '../../assets/call-police-removedbg.png';
// import carPolice from '../../assets/carPolice.png';
import { Divider } from 'antd';
import BackButton from 'components/backButton';

export default function AboutPage() {
  const backButtonStyle = {
    position: 'absolute',
    top: '10px',
    left: '15px',
  };
  return (
    <CardStyled>
      <BackButton styleProps={backButtonStyle} />

      <div className="contentBody">
        <div className="contentElement about">
          <div>
            <h2>
              <MobileOutlined />
              Sobre o Meu Celular
            </h2>
            <p>
              Bem-vindo ao Meu Celular, uma plataforma dedicada a ajudar os
              usuários a lidar com situações de roubo ou furto de seus
              dispositivos móveis. Nosso objetivo é proporcionar uma solução
              eficaz e segura para enfrentar essas situações de forma
              colaborativa com as autoridades policiais.
            </p>
          </div>
          <img src={aboutIcon} />
        </div>
        <Divider />

        <div className="contentElement mission">
          <img src={missionIcon} />
          <div>
            <h2>
              <CheckCircleOutlined />
              Nossa Missão
            </h2>
            <p style={{ textAlign: 'end' }}>
              Através do nosso sistema de alerta, estamos empenhados em otimizar
              e aprimorar a comunicação com as autoridades competentes, com o
              objetivo fundamental de maximizar as oportunidades de recuperação
              de dispositivos perdidos.
            </p>
          </div>
        </div>
        <Divider />

        <div className="contentElement tutorial">
          <div>
            <h2>
              <ContainerOutlined />
              Como Funciona
            </h2>
            <p>
              <b> 1. Cadastro Seguro: </b> Os usuários podem cadastrar os IMEIs
              de seus aparelhos celulares em nossa plataforma. Isso nos ajuda a
              criar uma base de dados segura e confiável.
              <br />
              <br />
              <b> 2. Ativação do Alerta: </b> Se o seu dispositivo for roubado
              ou furtado, você pode acessar a plataforma e ativar o alerta
              correspondente ao seu IMEI. Isso notificará imediatamente as
              autoridades policiais sobre o ocorrido.
              <br />
              <br />
              <b> 3. Colaboração com as Autoridades: </b> Trabalhamos em
              estreita colaboração com as autoridades policiais para fornecer
              informações essenciais durante abordagens. Se um dispositivo tiver
              um alerta ativo, as autoridades saberão da situação.
              <br />
              <br />
              <b> 4. Proteção de Dados: </b> Levamos a segurança e a privacidade
              dos seus dados a sério. Seus detalhes pessoais e informações de
              dispositivo são protegidos por medidas de segurança avançadas.
            </p>
          </div>
          <img src={tutorialIcon} style={{ marginTop: '4%' }} />
        </div>
        {/* <Divider /> */}

        {/* <div className="contentElement security">
                <a href="https://www.flaticon.com/free-icons/cop" title="cop icons"><img src={copIcon} style={{width: '100%', height: '230px'}} />
                    <div>
                        <h2> 
                            <SecurityScanOutlined />
                            Sua segurança é nossa prioridade
                        </h2>
                        <p>
                            O Meu Celular Ceará foi projetado com o seu bem-estar em mente. A segurança dos seus dados pessoais é uma prioridade absoluta, 
                            e empregamos as mais recentes tecnologias de proteção para garantir a confidencialidade e integridade das suas informações.
                            Aqui no Meu Celular Ceará, estamos comprometidos em criar um ambiente seguro e colaborativo, onde cada cidadão pode contribuir 
                            para a construção de uma comunidade mais protegida contra crimes envolvendo dispositivos móveis.
                            Junte-se a nós hoje e ajude a fortalecer a segurança em nosso estado. Cadastre seu celular, proteja-se contra roubos e furtos, 
                            e contribua para um Ceará mais seguro para todos.
                        </p>
                    </div>
                </div> */}
      </div>
    </CardStyled>
  );
}
