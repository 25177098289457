import { Modal } from 'antd';
import { useAuth } from 'hooks/auth';
import { SaveButton } from 'pages/home/modals/style';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { AuthService } from 'services/auth';
import { DEFAULT_ERROR_MESSAGE } from 'utils/defaultMessages';

interface ModalProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  handleConfirmation?: () => void;
  isLogged: boolean;
}

export const LgpdModal = ({
  isVisible,
  setIsVisible,
  handleConfirmation,
  isLogged,
}: ModalProps) => {
  const { signOut, client } = useAuth();
  const { setAgreeWithLgpd } = AuthService();
  const text =
    'Em conformidade com a Lei Geral de Proteção de Dados, ao clicar em  "Concordo", você autoriza o tratamento e compartilhamento de seus  dados pessoais para as finalidades específicas da plataforma "Meu  Celular", contribuindo nas ações de segurança pública.';

  const onAcceptTerms = useCallback(async () => {
    try {
      if (isLogged) {
        const cpf = client?.cpf;
        if (cpf) {
          await setAgreeWithLgpd(cpf);
          setIsVisible(false);
          sessionStorage.setItem('hasAgreedWithLgpd', 'true');
        } else {
          throw new Error('invalid cpf');
        }
      } else {
        setIsVisible(false);
        handleConfirmation && handleConfirmation();
      }
    } catch (err) {
      console.error(err);
      toast.error(DEFAULT_ERROR_MESSAGE);
    }
  }, []);

  return isLogged ? (
    <Modal
      visible={isVisible}
      footer={
        <div>
          <SaveButton color="green" onClick={onAcceptTerms}>
            Concordo
          </SaveButton>

          <SaveButton
            color="rgb(255, 69, 0)"
            onClick={() => {
              signOut();
            }}
          >
            Não concordo
          </SaveButton>
        </div>
      }
      zIndex={9999}
      title={null}
      closable={false}
    >
      <div>
        <p>{text}</p>
      </div>
    </Modal>
  ) : (
    <Modal
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      footer={
        <div>
          <SaveButton color="green" onClick={onAcceptTerms}>
            Concordo
          </SaveButton>
          <SaveButton
            color="rgb(255, 69, 0)"
            onClick={() => setIsVisible(false)}
          >
            Não concordo
          </SaveButton>
        </div>
      }
      zIndex={9999}
      title={null}
    >
      <div>
        <p>{text}</p>
      </div>
    </Modal>
  );
};
