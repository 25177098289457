import { Card } from 'antd';
import styled from 'styled-components';

export const AdequacyTermStyle = styled.section`
  width: 100%;
  padding: 2rem;
`;

export const CardStyled = styled(Card)`
  border-radius: 10px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .content-title {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;
  }

  .content-title-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: inherit;
  }

  .content-title h1 {
    margin: 0;
  }

  .content-text {
    font-size: 1.2em;
    text-align: justify;
  }

  .content-articles {
    padding: 1rem;
  }
`;
