import { Button, Modal } from "antd";
import styled from "styled-components";

export const Container = styled.div`
    @keyframes showAnimation {
        from {
            opacity: 0.1;
        }
        to {
            opacity: 1;
        }
    }
`;

export const AddAlertModal = styled(Modal)`

    .ant-select.ant-select-in-form-item::selection {
        background: #1DA57A;
    }

    .content-bo-info {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #fff;
        @media screen and (max-width: 620px) {
            flex-direction: column;
            align-items: baseline;
            gap: 0px;
        }
    }

    .content-bo-info img {
        width: 14px;
        height: 14px;
        filter: invert(1);
        margin-bottom: 3px;
    }

    .contentInstructions {
        width: 100%;
        margin-bottom: 20px;
        transition: all 0.5s ease-out;
        animation-name: showAnimation;
        animation-duration: 1s;
    }

    .contentInstructions img {
        width: 100%;
    }

    .ant-picker {
        width: 100%;
    }

    .ant-radio-group {
        display: flex;
        justify-content: center;

        @media screen and (max-width: 420px) {
            flex-wrap: wrap;
        }
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #1DA57A;
    }

    .ant-radio-inner::after {
        background-color: #1DA57A;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #1DA57A;
        border-color: #1DA57A;
    }

    .contentButton {
        display: flex; 
        justify-content: center; 
        gap:20px;
        flex-wrap: wrap
    }
`;

export const AddBoModal = styled(Modal)`
    .contentInstructions {
        width: 100%;
        margin-bottom: 20px;
        transition: all 0.5s ease-out;
        animation-name: showAnimation;
        animation-duration: 1s;
    }

    .ant-picker {
        width: 100%;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #1DA57A;
        border-color: #1DA57A;
    }

    .contentButton {
        display: flex; 
        justify-content: center; 
        gap:20px;
        flex-wrap: wrap
    }
`;

export const RestitutionModal = styled(Modal)`
    .contentButton {
        display: flex; 
        justify-content: center; 
        gap:20px;
        flex-wrap: wrap
    }
    
    .contentInstructions {
        width: 100%;
        margin-bottom: 20px;
        transition: all 0.5s ease-out;
        animation-name: showAnimation;
        animation-duration: 1s;
    }
`;

export const DeleteModal = styled(Modal)`
    .contentButton {
        display: flex; 
        justify-content: center; 
        gap:20px;
        flex-wrap: wrap
    }
`;

export const SaveButton = styled(Button)`
    border: 1px solid !important;
    border-color: ${(props) => props.color};
    color:  ${(props) => props.color};
    padding-top: 5px;

    &:hover {
        border-color: ${(props) => props.color} !important;
    color:  ${(props) => props.color} !important;
    }
`;
