import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  .row {
    height: 8rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 15px;
    flex-wrap: wrap;
    border-top: 3px solid #008442;

    /* width: 100%;
    padding: 10px 5px 0;
    border-top: 3px solid #008442;
    display: flex;
    justify-content: center; */
  }

  .content-row {
    width: 33.33%;
    text-align: center;
  }

  .sigla p {
    font-size: 1rem;
    margin-bottom: 1em;
    font-weight: 600;

    @media screen and (min-width: 2400px) {
      font-size: calc(1.1rem + 0.1vw);
    }
  }

  .sigla h2 {
    font: 0.8rem sans-serif;
    color: #485156;
    @media screen and (min-width: 2400px) {
      font-size: calc(1rem + 0.1vw);
    }
  }

  /* .endereco {
    width: 33.33%;
  } */

  .endereco h2 {
    font: 14px sans-serif;
    font-size: 1rem;
    /* margin-bottom: 1em; */
    line-height: 1.5;
    text-transform: uppercase;
    color: #485156;
    font-weight: 600;

    @media screen and (min-width: 2400px) {
      font-size: calc(1rem + 0.1vw);
    }
  }

  .endereco p {
    font: 12px sans-serif;
    font-size: 0.8rem;
    line-height: 1.5;
    text-transform: uppercase;
    color: #485156;

    @media screen and (min-width: 2400px) {
      font-size: calc(1rem + 0.1vw);
    }
  }

  /* .redesSociais {
    width: 33.33%;
    padding-left: 10px;
  } */

  .redesSociais p {
    font: 12px sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    color: #485156;

    @media screen and (min-width: 2400px) {
      font-size: calc(1rem + 0.1vw);
    }
  }

  .redesSociais h1 {
    font: 14px sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    text-transform: uppercase;
    font-weight: 600;
    color: #485156;

    @media screen and (min-width: 2400px) {
      font-size: calc(1rem + 0.1vw);
    }
  }

  .redesSociais h2 {
    font-size: 0.7rem;
    font: 10px sans-serif;
    color: #485156;
    margin-top: 20px;

    @media screen and (min-width: 2400px) {
      font-size: calc(1rem + 0.1vw);
    }
  }

  @media (max-width: 860px) {
    .redesSociais h2 {
      margin-top: 35px;
    }
  }

  .icon img {
    width: 80%;
  }

  @media screen and (max-width: 600px) {
    .row {
      display: block;
      justify-content: center;
      text-align: center;
      width: 100%;
    }
    .sigla {
      margin-top: 5%;
      padding-top: 0;
      font-size: 16px;
      width: 100%;
    }

    .sigla p {
      padding: 0;
      margin-bottom: 1.5em;
    }

    .endereco h2 {
      font: 12px sans-serif;
      line-height: 1.5;
      text-transform: uppercase;
      margin-bottom: 1.5em;
      color: #485156;
      font-weight: 600;
    }
    .endereco p {
      font: 10px sans-serif;
      line-height: 1.5;
      text-transform: uppercase;
      color: #485156;
    }
    .endereco {
      margin-top: 5%;
      width: 100%;
    }
    .redesSociais {
      width: 100%;
      justify-content: center;
      display: flex;
      flex-flow: wrap;
      flex-direction: column;
    }

    .redesSociais h1 {
      margin-bottom: 1.5em;
    }

    .icon {
      margin-top: -10px;
      display: block;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-height: 355px) {
    .container {
      margin-top: 20%;
    }
  }

  @media screen and (min-height: 356px) and (max-height: 600px) {
    .container {
      margin-top: 16%;
    }
  }
  .direitos h2 {
    font: 10px sans-serif;
    font-size: 0.7rem;
    color: #485156;
  }
`;
