import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border: 0;
  @media screen and (max-width: 767px) {
    min-height: 80vh;
  }
  @media screen and (max-width: 420px) {
    min-height: 95vh;
  }
`;

const Wrapper = styled.div`
  //margin: 5%;
  /* margin-left: 5%;
  margin-right: 5%; */
  width: 100%;
  height: 65vh;

  @media screen and (max-width: 767px) {
    min-height: 80vh;
  }

  .container {
    /* margin-top: 10px; */
    width: 100%;
  }

  .conteudoModal {
    background: #008442 !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-body {
    background: chartreuse !important;
  }

  .subtitle {
    height: 50px;
    width: 100%;
    background: #cfcfcf;
    color: #5d5d5d;
    padding-top: 17px;
    padding-left: 20px;
    font-weight: 600;
    border: 1px solid #cecece;
    border-radius: 5px 5px 0px 0px;
  }

  .row {
    width: 100%;
    display: flex;
    overflow: auto;
    overflow-x: scroll;
    /* justify-content: center; */

    padding: 0px 25px;
    background: #fdfdfd;
    scroll-behavior: smooth;

    box-shadow: 0 2px 2px rgb(0 0 0 / 11%);
    border-radius: 5px;
  }

  .previusPageIcon {
    position: absolute;
    top: 50%;
    right: 95%;
    border-radius: 50%;
  }

  .nextPageIcon {
    position: absolute;
    top: 50%;
    left: 95%;
    border-radius: 50%;
  }

  .card-01 {
    /* background: #03d29f; */
    position: relative;
    flex: 1;
    max-width: 600px;
    height: 150px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  }

  .card-02 {
    background: #50a7ff;
    position: relative;
    flex: 1;
    max-width: 460px;
    height: 200px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .card-04 {
    background: transparent;
    position: relative;
    flex: 1;
    max-width: 460px;
    height: 200px;
    margin: 10px;
  }

  .card-03 {
    background: #fff;
    position: relative;
    flex: 1;
    max-width: 580px;
    height: 120px;
    margin-left: 15px;
    margin-top: 15px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .card-icon-orange {
    background: #fc6836;
    position: relative;
    flex: 1;
    max-width: 90px;
    height: 90px;
    margin: 0px;
    margin-top: -135px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .card-icon-green {
    background: #03d29f;
    position: relative;
    flex: 1;
    max-width: 90px;
    height: 90px;
    margin: 0px;
    margin-top: -135px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .card-icon-blue {
    background: #368cfc;
    position: relative;
    flex: 1;
    max-width: 90px;
    height: 90px;
    margin: 0px;
    margin-top: -135px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .card-icon-yello {
    background: #ffb52c;
    position: relative;
    flex: 1;
    max-width: 90px;
    height: 90px;
    margin: 0px;
    margin-top: -135px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .card-icon-red {
    background: #fc3667;
    position: relative;
    flex: 1;
    max-width: 90px;
    height: 90px;
    margin: 0px;
    margin-top: -135px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .iconComponent {
    height: 40px;
    width: 40px;
    margin: 25px;
    fill: #fff;
    transition: 500ms;
  }

  .card-01:hover .iconComponent {
    height: 50px;
    width: 50px;
    margin: 20px;
  }

  .img-icon img {
    height: 40px;
    width: 40px;
    margin: 25px;
    transition: 650ms;
  }

  .card-01:hover .img-icon img {
    height: 50px;
    width: 50px;
    margin: 20px;
  }

  .title-card {
    font-size: 25px;
    font-weight: 600;
    color: #008442;
    flex: 1;
    margin-left: 97px;
    margin-top: -60px;
  }

  .subtitle-card {
    font-size: 16px;
    flex: 1;
    padding-top: 50px;
    padding-right: 10px;
    margin-left: 80px;
  }

  .buttonAdd {
    background: #f5f5f5;

    border-radius: 10px;
    border: 1px solid #b9b9b9;
    box-sizing: border-box;
    margin: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #404040;
  }

  .buttonAdd button {
    border: none;
    background: none;
    width: 220px;
    height: 100%;
  }

  buttonAlert {
    margin: 10px;
  }

  @media (max-width: 600px) {
    .row {
      padding: 0px 20px;
    }

    .buttonAdd button {
      width: 200px;
      margin: 30px 0px;
    }
  }

  @media (max-width: 800px) {
    .card-01 {
      flex: 100%;
      max-width: 600px;
    }
  }

  @media (max-width: 800px) {
    .subtitle-card {
      flex: 100%;
      max-width: 600px;
    }
  }

  @media screen and (min-width: 500px) {
    .title-card {
      font-size: calc(16px + (8 / 1200) * 100 * 1vw);
    }
  }
`;

export default Wrapper;
