import styled from 'styled-components';

export const OutletContainer = styled.div`
  width: 100%;
  min-height: 73vh;
  padding: 20px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  background: rgba(240, 240, 240, 0.8);
  overflow: auto;
  @media screen and (max-width: 420px) {
    min-height: 100vh;
  }
`;
