import {
  FolderOpenOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import CardAntd from 'components/card/cardAntd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from './style';

interface SliderProps {
  data: any;
  setShowAlertModal: any;
  setShowBOModal: any;
  setShowRestitutionModal: any;
  setShowDeleteModal: any;
  setCardId: any;
  // setModalVisibility: any
  // showModal: any,
  // showDeleteModal: any,
  // showModalRestituicao: any
}

export default function Slider({
  data,
  setShowAlertModal,
  setShowBOModal,
  setShowRestitutionModal,
  setShowDeleteModal,
  setCardId,
}: SliderProps) {
  const navigate = useNavigate();
  let pageSize = 3;
  const width = screen.width;
  const isMobile = width <= 767;
  const isTablet = width >= 768 && width <= 991;

  if (isMobile) {
    pageSize = 1;
  } else if (isTablet) {
    pageSize = 2;
  } else {
    pageSize = 3;
  }
  const maxPage = Math.ceil(data.length / pageSize);
  const [page, setPage] = useState({
    first: 0,
    last: pageSize,
  });
  const currentPage = useRef(0);

  const onChangePageBySlickDots = (currentSlide: any) => {
    currentPage.current = currentSlide;
    currentSlide = currentSlide + 1;
    setPage(prev => ({
      first: (prev.first = (currentSlide - 1) * pageSize),
      last: (prev.last = currentSlide * pageSize),
    }));
  };

  const onChangePageByButton = (orientation: string) => {
    if (orientation === 'next') {
      currentPage.current = currentPage.current + 1;
      setPage(prev => ({
        first: prev.first + pageSize,
        last: prev.last + pageSize,
      }));
    } else {
      currentPage.current = currentPage.current - 1;
      setPage(prev => ({
        first: prev.first - pageSize,
        last: prev.last - pageSize,
      }));
    }
  };

  const nextPageByAutoPlay = useCallback(() => {
    if (currentPage.current < maxPage - 1 && currentPage.current >= 0) {
      onChangePageByButton('next');
    } else {
      currentPage.current = 0;
      setPage({
        first: 0,
        last: pageSize,
      });
    }
  }, [currentPage.current, maxPage]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextPageByAutoPlay();
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, [nextPageByAutoPlay]);

  return (
    <Container
      title="Dispositivos"
      key={Math.random() * data?.length}
      extra={
        <Button
          className="addDeviceButton"
          onClick={() => navigate('/registrar-dispositivo')}
        >
          <PlusOutlined />
          dispositivo
        </Button>
      }
    >
      {data?.length ? (
        <>
          <div className="contentDevices">
            {data?.slice(page.first, page.last).map((device: any) => (
              <CardAntd
                key={device.id}
                id={device.id}
                alert={device.statusAlerta}
                producer={device.fabricante}
                model={device.modelo}
                imeis={device.imeis}
                setShowAlertModal={setShowAlertModal}
                setShowBOModal={setShowBOModal}
                setShowRestitutionModal={setShowRestitutionModal}
                setShowDeleteModal={setShowDeleteModal}
                setCardId={setCardId}
                // setModalVisibility={setModalVisibility}
                // onClickAlert={() => showModal(device.id, device.statusAlerta)}
                // onClickDelete={() => showDeleteModal(device.id)}
                // onClickRestituicao={() => showModalRestituicao(device.id)}
              />
            ))}
          </div>

          <Button
            disabled={page.first === 0}
            onClick={() => {
              onChangePageByButton('prev');
            }}
            className="previusPageIcon"
            icon={<LeftOutlined />}
          />

          <Button
            disabled={page.last >= data?.length}
            onClick={() => {
              onChangePageByButton('next');
            }}
            className="nextPageIcon"
            icon={<RightOutlined />}
          />

          <ul className="slick-dots">
            {data.slice(0, maxPage).map((device: any, index: number) => (
              <li
                key={device.id}
                style={{
                  background: currentPage.current === index ? 'green' : '',
                }}
              >
                <Button onClick={() => onChangePageBySlickDots(index)}>
                  {index}
                </Button>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="emptyDevices">
          <FolderOpenOutlined />
          <h2>Sem Dispositivos Cadastrados</h2>
        </div>
      )}
    </Container>
  );
}
