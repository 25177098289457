import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEncription } from 'utils/encription';
import { Form, Button, Input, Spin, Modal } from 'antd';
import {
  CheckCircleOutlined,
  LeftOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import { toast } from 'react-toastify';
import styles from './styles.module.css';
import CodeVerification from '../../services/code.verification';

const red = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/;

const strongPasswordRegex = new RegExp(red);

export default function PageValidation() {
  const [form] = Form.useForm();
  const [resendCodeForm] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openResendCodeModal, setOpenResendCodeModal] = useState(false);
  const [resendCodeTimer, setResendCodeTimer] = useState(0);
  const [cpf, setCpf] = useState('');

  const email = location.state?.email;
  const question = location.state?.question;
  const answer = location.state?.answer;
  const date = location.state?.date;

  const onCreatePassword = useCallback(
    async values => {
      try {
        setLoading(true);
        if (values.password !== values.passwordConfirmation) {
          toast.error('As senhas não coincidem');
          return;
        }
        const cpfFormated = cpf.replaceAll('.', '').replaceAll('-', '');
        const params = {
          ...values,
          cpf: cpfFormated,
        };
        const { data } = await CodeVerification.saveCodeVerification(params);
        if (data?.messageForUser) {
          toast.info(data.messageForUser);
        } else {
          toast.info('Conta criada');
        }
        navigate('/login');
      } catch (e) {
        if (e?.response) {
          if (e.response.status === 401) {
            return toast.error('Dados inválidos');
          } else if (e.response.status === 500) {
            return toast.error('Falha de conexão com o servidor');
          } else {
            return toast.error(
              e.response.status || 'Ocorreu um erro, por favor tente novamente'
            );
          }
        }
        if (e?.request) {
          return toast.error('Falha de conexão com o servidor');
        }
        toast.error(
          e || e?.messageForUser || 'Ocorreu um erro, por favor tente novamente'
        );
      } finally {
        setLoading(false);
      }
    },
    [cpf]
  );

  const onResendCode = useCallback(
    async values => {
      try {
        if (resendCodeTimer === 0) {
          setLoading(true);
          const cpfFormated = cpf.replaceAll('.', '').replaceAll('-', '');
          const { data } = await CodeVerification.resendEmail(
            values?.email,
            cpfFormated
          );
          if (data?.messageForUser) {
            toast.info(data.messageForUser);
            setOpenResendCodeModal(false);
            setResendCodeTimer(prev => prev + 60);
            localStorage.setItem('resendCodeTimer', resendCodeTimer + 60);
          }
        } else {
          toast.info(
            `Aguarde ${resendCodeTimer} segundos para enviar um novo código`
          );
        }
      } catch (err) {
        toast.error(err || 'Ocorreu um erro ao tentar reenviar código');
      } finally {
        setLoading(false);
      }
    },
    [resendCodeTimer, cpf]
  );

  useEffect(() => {
    const storedTime = localStorage.getItem('resendCodeTimer');
    const parsedTime = parseInt(storedTime);

    if (parsedTime && parsedTime > 0) {
      setResendCodeTimer(parsedTime);
    }

    const cpfEncrypted = location.state?.cpf;

    if (cpfEncrypted) {
      try {
        const decryptedCpf = useEncription(cpfEncrypted, 'decrypt');
        form.setFieldsValue({
          cpf: decryptedCpf,
        });
        setCpf(decryptedCpf);
      } catch (error) {
        console.error('Erro ao descriptografar o CPF:', error);
      }
    }
  }, []);

  useEffect(() => {
    let timer = 0;
    if (resendCodeTimer > 0) {
      timer = setTimeout(() => {
        localStorage.setItem('resendCodeTimer', resendCodeTimer - 1);
        setResendCodeTimer(prevTime => prevTime - 1);
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [resendCodeTimer]);

  return (
    <Spin spinning={loading} className={styles.spin}>
      <Form
        layout="vertical"
        onFinish={onCreatePassword}
        form={form}
        name="basic"
      >
        <div className={styles.container}>
          <div className={styles.cardVerification}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <LeftOutlined
                id={styles.backButton}
                onClick={() => navigate('/login')}
              />
              <h1>Validação da conta</h1>
            </div>
            <p>
              Copie e cole o código de segurança enviado para o e-mail
              informado.
            </p>
            <Form.Item
              initialValue={cpf}
              name="cpf"
              label="CPF"
              mask="999.999.999-99"
              rules={[{ required: true, message: 'Obrigatório' }]}
            >
              <Input
                className={styles.input}
                required
                disabled="disabled"
                type="text"
                placeholder="CPF"

                /* onBlur={verificaVazio} */
              />
            </Form.Item>
            <Form.Item
              label="Código"
              name="code"
              rules={[{ required: true, message: 'Obrigatório' }]}
            >
              <Input
                className={styles.input}
                required
                type="text"
                placeholder="Digite o código"
                /* onBlur={verificaVazio} */
              />
            </Form.Item>
            <Form.Item
              label="Senha"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Obrigatório',
                },
                { min: 8 },
                {
                  validator: (_, value) =>
                    value && value.match(strongPasswordRegex)
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            'Senha informada deve ser forte (maiusculo, minusculo, numero e caracter especial)'
                          )
                        ),
                },
              ]}
            >
              <Input.Password
                className={styles.input}
                required
                type="password"
                placeholder="Digite a senha"
              />
            </Form.Item>
            <Form.Item
              label="Confirmar senha"
              name="passwordConfirmation"
              rules={[
                { required: true, message: 'Obrigatório' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('As senhas não coincidem!')
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                className={styles.input}
                required
                placeholder="Confirme a senha"
                type="text"
              />
            </Form.Item>
            <div className={styles.areaButton}>
              <Button className={styles.btnConfirmar} htmlType="submit">
                <CheckCircleOutlined /> Validar conta
              </Button>

              <Button
                className={styles.btnResendEmail}
                onClick={() => setOpenResendCodeModal(true)}
              >
                <RollbackOutlined /> Reenviar e-mail
              </Button>
            </div>
          </div>
        </div>
      </Form>
      <Modal
        visible={openResendCodeModal}
        onCancel={() => setOpenResendCodeModal(false)}
        title="Reenviar E-mail"
        footer={null}
      >
        <Spin spinning={loading}>
          <Form form={resendCodeForm} onFinish={onResendCode}>
            <Form.Item
              initialValue={email}
              name="email"
              label="E-mail"
              rules={[
                {
                  required: true,
                  message: 'Obrigatório',
                },
                { type: 'email', message: 'Inválido' },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              initialValue={question}
              name="question"
              label="Pergunta de segurança"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="answer"
              label="Resposta"
              rules={[
                {
                  required: true,
                  message: 'Obrigatório',
                },
                () => ({
                  validator(_, value) {
                    if (!value || answer === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('A resposta está incorreta!')
                    );
                  },
                }),
              ]}
            >
              <Input required />
            </Form.Item>

            <Form.Item
              name="date"
              label="Data de nascimento"
              rules={[
                {
                  required: true,
                  message: 'Obrigatório',
                },
                () => ({
                  validator(_, value) {
                    if (!value || date === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('A resposta está incorreta!')
                    );
                  },
                }),
              ]}
            >
              <Input
                required
                name="confirmDate"
                placeholder="Data nascimento"
                type="date"
              />
            </Form.Item>
            <div style={{ textAlign: 'end' }}>
              <Button
                className={styles.btnConfirmar}
                htmlType="submit"
                disabled={resendCodeTimer > 0}
              >
                {resendCodeTimer > 0
                  ? `${resendCodeTimer} segundos restantes`
                  : 'Reenviar'}
              </Button>
            </div>
          </Form>
        </Spin>
      </Modal>
    </Spin>
  );
}
