import { toast } from 'react-toastify';
import api from './api';
import { apiAuth } from './auth';

apiAuth.interceptors.request.use(async config => {
  const token = sessionStorage.getItem('@SSPDS_ALERTA:token');
  config.headers['Authorization'] = `Bearer ${token}`;
  return config;
});

const UserService = {
  errorHandler: (err: any) => {
    toast.error(err);
  },

  getuserByCpf: async (cpf: string) => {
    try {
      return await apiAuth.get(`/user/getFiltered?cpf=${cpf}`);
    } catch (err: any) {
      UserService.errorHandler(err?.message);
    }
  },

  getUserById: async () => await api.get(`/cidadao/user`),
};

export default UserService;
